;(function() {
  'use strict'

  Service.$inject = ["$transitions", "$rootScope", "$q", "$state", "$document", "$compile", "glPrefs", "glAnalytics", "glDialog", "subscriberService"];
  angular.module('core.tips').factory('glTips', Service)

  /* @ngInject */
  function Service(
    $transitions,
    $rootScope,
    $q,
    $state,
    $document,
    $compile,
    glPrefs,
    glAnalytics,
    glDialog,
    subscriberService
  ) {
    var prefs = glPrefs.create('tips')
    var body = angular.element($document[0].body)
    var readyListener = null

    // the page config with tips and their options
    // Warnings:
    // 1. New page and tip ids must be unique. Use uuid's, e.g. https://www.uuidgenerator.net/version4
    // 2. Don't change existing page or tip ids as localStorage refers to these
    // 3. Previously, integers were used as the unique ids.
    //    Old tips still use these for backwards compatibility (e.g. google analytics and localstorage)

    // Old tips ids:
    // Highest page id: 11
    // Highest tip id: 47

    var pages = [
      {
        id: 'd76cb13f-c848-4239-acad-972206b4dec7',
        state: ['store', 'publisherStore', 'publication'],
        tips: [
          // {
          //   id: '44f97d8f-137d-4bc9-bc58-20a0dee77a67',
          //   selector: 'switcher',
          //   startFn: userService.isLoggedIn,
          //   title: 'Switch to Studio',
          //   desc: 'Create your own research projects in Glow Studio!',
          //   doneEvent: 'gSwitcher:open',
          //   fixed: true,
          // },
        ],
      },
      {
        id: 10,
        state: 'projects',
        tips: [
          // {
          //   id: '0e7c9acd-0b9f-4299-958c-e056f6fbc9b4', // shares a tipId on the packs page
          //   selector: 'switcher',
          //   title: 'Switch to Insights',
          //   desc:
          //     'Check out world class thought leadership reports with Glow Insights!',
          //   doneEvent: 'gSwitcher:open',
          //   defrost: 2,
          // },
          {
            id: 42,
            selector: 'project',
            title: 'Project',
            desc:
              'You can create your own private surveys in a project. Click below to get started.',
            doneState: 'project',
          },
          {
            id: 43,
            selector: 'create-project',
            title: 'Create Project',
            desc:
              'When you upgrade to Premium you can create new Projects for your different business initiatives.',
            doneEvent: 'projects:create',
          },
          {
            id: 44,
            selector: 'unread-messages',
            title: 'Inbox',
            desc:
              'Respond to messages and survey follow-up requests from your Inbox.',
            doneState: 'activity',
          },
        ],
      },
      {
        id: 5,
        state: ['account'],
        tips: [
          {
            id: 20,
            selector: 'logo',
            startFn: noLogo,
            title: 'Add your logo',
            desc: 'Click to add or change your logo.',
            doneEvent: 'account:logo-updated',
          },
        ],
      },
      {
        id: 6,
        state: 'page.general',
        tips: [
          {
            id: 25,
            selector: 'logo',
            title: 'Add your Page logo',
            desc: 'Click to add or change the logo displayed on your Page.',
            doneEvent: 'page:logo-updated',
          },
          {
            id: 26,
            selector: 'feature',
            title: 'Add your Page banner',
            desc: 'Click to add or change the banner displayed on your Page.',
            doneEvent: 'page:feature-updated',
          },
          {
            id: 23,
            selector: 'title',
            title: 'Update the title',
            desc: 'Update the title of your Page.',
            hideFocus: true,
            doneBlur: true,
          },
          {
            id: 24,
            selector: 'desc',
            title: 'Add a description',
            desc:
              'Add a description or message that will be displayed on your Page.',
            hideFocus: true,
            doneBlur: true,
          },
          {
            id: 27,
            selector: 'color',
            title: 'Add your theme colour',
            desc: [
              'Choose a colour that represents your brand. This will be used on buttons throughout your',
              'Page.',
            ].join(' '),
            doneClick: true,
          },
          {
            id: 28,
            selector: 'hashtag',
            title: 'Add a Page hashtag',
            desc:
              'Reserve a hashtag to allow people to find you more easily on Glow.',
            hideFocus: true,
            doneBlur: true,
          },
          {
            id: 29,
            selector: 'save',
            title: 'Save',
            desc:
              'Click this button at any time to save any changes you have made.',
            doneEvent: 'page-general:saved',
          },
          {
            id: 30,
            selector: 'modules',
            title: 'Page modules',
            desc:
              'Your Page contains modules that your audience can interact with.',
            doneState: 'page.modules',
          },
        ],
      },
      {
        id: 7,
        state: 'page.modules',
        tips: [
          {
            id: 31,
            selector: 'module-settings',
            title: 'Module settings',
            desc:
              'Change the settings of a module by clicking on the cog icon.',
            doneEvent: 'page-modules:settings-closed',
          },
          {
            id: 34,
            selector: 'view',
            title: 'View Page',
            desc: 'View your Page and see your changes.',
            doneClick: true,
          },
        ],
      },
      {
        id: 8,
        state: 'survey-reporting', // DEPRECATED: tips re-added to `state: 'survey'` below
        tips: [
          {
            id: 45,
            selector: 'new-filter-set',
            title: 'New Filter',
            desc: 'Add filters and save them to view again later.',
          },
          {
            id: 46,
            selector: 'filter-set-menu',
            title: 'Edit Filter',
            desc: 'Filters can be edited here.',
          },
          {
            id: 37,
            selector: 'edit-filter',
            title: 'Edit filter',
            desc: 'Edit this existing filter.',
          },
          {
            id: 38,
            selector: 'add-edit-filter-chart',
            title: 'Add & edit filter',
            desc:
              'You can also add and edit filters by clicking on charts or tables.',
          },
          {
            id: 39,
            selector: 'remove-filter',
            title: 'Remove filter',
            desc: 'Remove this existing filter.',
          },
        ],
      },
      {
        id: 11,
        state: 'interactiveAnalysis',
        tips: [
          {
            id: 47,
            selector: 'survey-kits',
            title: 'Survey Kits',
            desc:
              'This report was made from scratch on Glow. Create your own survey using our templates & guides in minutes.',
            fixed: true,
          },
        ],
      },
      {
        id: 'd3485c3c-30ef-4a5d-ad79-652b28a595cc',
        state: 'survey',
        tips: [
          // Editor tab
          {
            id: 'f8409d7b-d6c9-4cbb-a1b5-6ec70310353a',
            selector: 'add-image',
            title: 'Add an image',
            desc: 'Add an image to your survey intro.',
            doneEvent: 'survey-editor:survey-image-added',
          },
          {
            id: '8497067d-d6f1-4bf7-9106-65de9661902c',
            selector: 'locked-question',
            title: 'Locked questions',
            desc: 'Questions with a padlock icon mean you cannot edit them.',
          },
          {
            id: 'c3d169c5-f945-43eb-ae31-ec704c8b0169',
            selector: 'preview',
            title: 'Preview survey',
            desc:
              'Preview your survey as your respondents will see it. This will not record responses.',
            doneEvent: 'survey-preview:landed',
            fixed: true,
          },
          // Preview tab
          {
            id: 'c7b1d24e-b56d-4c7d-beed-314d60560a88',
            selector: 'survey-preview-link',
            title: 'Share Preview',
            desc: 'Copy and share a preview link to your survey.',
            doneEvent: 'survey-preview:preview-link-copied',
          },
          {
            id: '4a498b35-ee1f-4445-b2d6-008ba488334b',
            selector: 'audiences',
            title: 'Start collecting responses!',
            desc:
              'When your survey is ready, add a Response Channel to start collecting responses.',
            startFn: createEventTracker('survey-preview:done-pressed'),
            doneClick: true,
            fixed: true,
          },
          // Analysis tab
          {
            id: 'd6620753-2753-40c9-b392-79820d0d44be',
            selector: 'new-filter-set',
            title: 'New Filter',
            desc: 'Add filters and save them to view again later.',
            fixed: true,
          },
          {
            id: '0f4a7e7e-a70c-4a10-bc7f-6dab3283c28f',
            selector: 'filter-set-menu',
            title: 'Edit Filter',
            desc: 'Filters can be edited here.',
            fixed: true,
          },
          {
            id: '9035494b-a249-45c9-98df-0237a0098d65',
            selector: 'edit-filter',
            title: 'Edit filter',
            desc: 'Edit this existing filter.',
          },
          {
            id: '563d6569-d631-43ef-bd29-1f5f39017804',
            selector: 'add-edit-filter-chart',
            title: 'Add & edit filter',
            desc:
              'You can add and edit filters by clicking on charts or tables.',
          },
          {
            id: '6911abdd-c104-4388-9d34-cdbeee753d7a',
            selector: 'remove-filter',
            title: 'Remove filter',
            desc: 'Remove this existing filter.',
          },
        ],
      },
    ]

    var PROGRESS_PREF = 'progress'
    var progress = _.defaults(prefs.get(PROGRESS_PREF), {
      ignored: {}, // map of page ids
      done: {}, // map of tip ids
      defrost: {}, // map of tip ids
    })
    var page
    var activeTip

    init()

    return {
      ready: ready,
      next: next,
      markAsDone: markAsDone,
      ignore: ignore,
      resetCurrent: resetCurrent,
    }

    /*
     * init
     * @desc validate and initialise the service
     */
    function init() {
      $transitions.onStart(null, onStateChange)
    }

    function onStateChange() {
      if (readyListener) {
        readyListener.reject()
        readyListener = null
      }
    }

    /*
     * ready
     * @desc signify that a page is ready for tips to be shown if any are available
     */
    function ready() {
      // cancel any previous readyListener
      if (readyListener) {
        readyListener.reject()
      }
      // create a readyListener
      readyListener = $q.defer()
      readyListener.promise.then(function() {
        page = findPageByState($state.current.name)
        if (!page) {
          return
        }
        if (!progress.ignored[page.id]) {
          $rootScope.$applyAsync(next)
        }
      })
      glDialog.waitUntilInactive().then(readyListener.resolve)
    }

    /*
     * next
     * @desc attempts to find the next tip on the current page - and if one - builds it
     */
    function next() {
      $rootScope.$applyAsync(function() {
        if (!page) {
          return
        }

        var options
        _.each(page.tips, function(tip) {
          // make sure the tip isnt done
          if (!isDone(tip.id)) {
            tip = _.cloneDeep(tip)

            // check defrost
            if (tip.defrost) {
              var level = progress.defrost[tip.id] || 0
              level++
              // if still defrosting, update and skip
              if (level < tip.defrost) {
                progress.defrost[tip.id] = level
                saveProgress()
                return
              }
            }

            // it must have a target element if a selector is specified
            if (tip.selector) {
              tip.target = $document[0].querySelectorAll(
                '[tip="' + tip.selector + '"]'
              )[0]
              if (!tip.target) {
                return
              }
            }

            //
            if (tip.startFn) {
              var shouldStart = tip.startFn() === true
              if (!shouldStart) {
                return
              }
            }

            // set the options
            options = tip

            // break out of the each loop :)
            return false
          }
        })

        // no options, no tip!
        if (!options) {
          return
        }

        // do nothing if the tip is already active
        if (activeTip && activeTip.id === options.id) {
          return
        }

        // remove active tip if it exists
        if (activeTip) {
          activeTip.scope.$destroy()
          activeTip.elem.remove()
        }

        // build it!
        build(options)
      })
    }

    /*
     * build
     * @desc adds a tip directive to the DOM where it will show itself depending on options specified
     * @param {Object} options The tip options as defined above
     */
    function build(options) {
      var scope = $rootScope.$new()
      scope.options = options
      var elem = $compile('<gl-tip />')(scope)
      body.append(elem)
      activeTip = {
        id: options.id,
        scope: scope,
        elem: elem,
      }
    }

    /*
     * ignore
     * @param {Boolean} allPages Whether to ignore tips on all pages, or just this page
     */
    function ignore(allPages) {
      if (allPages) {
        _.each(pages, function(page) {
          progress.ignored[page.id] = true
        })
        track('cancel-all-tips')
      } else {
        progress.ignored[page.id] = true
        track('cancel-tips-for-page', page.id)
      }
      saveProgress()
    }

    function findPageByState(state) {
      return _.find(pages, function(page) {
        if (_.isArray(page.state)) {
          return _.includes(page.state, state)
        }
        return page.state === state
      })
    }

    /*
     * resetCurrent
     * @desc clear current page tips marked as complete and re-display the tips from the start
     */
    function resetCurrent() {
      page = findPageByState($state.current.name)
      if (!page) {
        return
      }

      $rootScope.$broadcast('tips:reset')

      delete progress.ignored[page.id]
      _.each(page.tips, function(tip) {
        delete progress.done[tip.id]
      })
      saveProgress()
      ready()
      track('reset-tips-for-page', page.id)
    }

    /*
     * markAsDone
     * @desc marks a point as done
     * @param {String} pointId The point ID
     */
    function markAsDone(tipId) {
      progress.done[tipId] = true
      delete progress.defrost[tipId]
      saveProgress()
      track('complete', tipId)
    }

    /*
     * isDone
     * @desc determine if a tip is done
     * @param {String} tipId The tip ID
     * @returns {Boolean}
     */
    function isDone(tipId) {
      return progress.done[tipId]
    }

    /*
     * noLogo
     * @desc used by one of the tour points to decide if it should be shown
     * @returns {Boolean} isNoLogo whether the business has set a logo
     *
     */
    function noLogo() {
      var subscriber = subscriberService.getSubscriber()
      return !subscriber.portalTheme.logoImageUrl
    }

    function createEventTracker(event) {
      var didFire
      $rootScope.$on(event, function() {
        didFire = true
      })
      return function() {
        return didFire
      }
    }

    /*
     * track
     * @desc track analytics
     * @param {String} action
     * @param {String} label
     * @param {Number} value
     */
    function track(action, label, value) {
      glAnalytics.track('Tips', action, label, value)
    }

    /*
     * saveProgress
     * @desc persists the tip completion progress to localStorage
     */
    function saveProgress() {
      prefs.set(PROGRESS_PREF, progress)
    }
  }
})()
