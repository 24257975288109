;(function() {
  'use strict'

  Controller.$inject = ["channelCostService"];
  angular.module('app.core').component('channelConfirmCharges', {
    templateUrl: 'channel-confirm-charges.html',
    controller: Controller,
    bindings: {
      channel: '<',
      onConfirm: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(channelCostService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.cost = channelCostService.calculate(ctrl.channel)
    }
  }
})()
