;(function() {
  'use strict'

  Controller.$inject = ["$state", "accountService", "featureService", "projectService", "subscriberService", "userService", "publicationService", "suggestionsService", "subscriberPromotionService", "accountSetupDialog", "steadfastDialog", "bundleService", "uacService", "glPrefs"];
  angular.module('app.dashboard').controller('Dashboard', Controller)

  /* @ngInject */
  function Controller(
    $state,
    accountService,
    featureService,
    projectService,
    subscriberService,
    userService,
    publicationService,
    suggestionsService,
    subscriberPromotionService,
    accountSetupDialog,
    steadfastDialog,
    bundleService,
    uacService,
    glPrefs
  ) {
    var ctrl = this
    var prefs = glPrefs.create('dashboard-page')
    var prefsOptions = { user: true }

    ctrl.$onInit = onInit
    ctrl.hideWelcome = hideWelcome
    ctrl.isLoading = isLoading
    ctrl.createProject = createProject
    ctrl.viewProject = viewProject
    ctrl.executeSuggestion = suggestionsService.execute
    ctrl.viewBundles = viewBundles

    function onInit() {
      ctrl.welcomeHidden = prefs.get('welcomeHidden', prefsOptions)
      ctrl.user = userService.getUser()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.didSignup = $state.params.didSignup
      ctrl.promotion = subscriberPromotionService.getApplied()
      ctrl.isSteadfast = ctrl.subscriber.subscriptionCode === 'steadfast'
      loadProjects()
      loadReports()
      loadSuggestions()
      loadBundles()

      // COMMENTING this out because the link to steadfast guide is broken
      // if (ctrl.didSignup && ctrl.isSteadfast) {
      //   steadfastDialog.show()
      // }
      if (ctrl.didSignup && ctrl.promotion) {
        subscriberPromotionService
          .showThanksDialog(ctrl.promotion)
          .finally(function() {
            accountSetupDialog.show().finally(function() {
              /**
               * HACK
               * ----
               * After users sign up to a promotion we want to show
               * them they are on the free plan and what other the plans
               * have to offer (also upsell).
               * But the `enterprise` promotion is special in that the
               * backend automatically puts the user on the enterprise plan.
               * In this case, we don't want to show the user the plans because
               * they're already on the best one.
               */
              if (ctrl.promotion.code !== 'enterprise') {
                accountService.promptUpgrade({ context: 'welcome' })
              }
            })
          })
      }
    }

    function hideWelcome() {
      ctrl.welcomeHidden = true
      prefs.set('welcomeHidden', true, prefsOptions)
    }

    function isLoading() {
      return (
        !ctrl.projects ||
        (ctrl.library.loading && !ctrl.library.docs) ||
        (ctrl.store.loading && !ctrl.store.docs)
      )
    }

    function loadProjects() {
      projectService.load().then(function(projects) {
        ctrl.projects = projects
      })
    }

    function loadReports() {
      ctrl.library = publicationService.getByView('library')
      ctrl.store = publicationService.getByView('store', 'inStore eq true', {
        top: 12,
        skip: 0,
        orderby: 'createdAt desc',
      })
    }

    function loadSuggestions() {
      ctrl.suggestions = suggestionsService.get()
    }

    function loadBundles() {
      bundleService.loadAll().then(function(bundles) {
        ctrl.bundles = bundles
      })
    }

    function createProject() {
      accountService
        .promptUpgrade({
          bypassCondition: featureService.canCreateProject(),
          context: 'more-projects',
        })
        .then(function() {
          if (!uacService.canManageProjects(true)) return
          projectService.edit().then(viewProject)
        })
    }

    function viewProject(project) {
      $state.go('project', { id: project.id })
    }

    function viewBundles() {
      bundleService.showSelector().then(function(bundle) {
        accountService.showPurchaseDialog({
          type: 'bundle',
          bundle: bundle,
        })
      })
    }
  }
})()
