;(function() {
  'use strict'

  Controller.$inject = ["Plan", "glAnalytics", "glToast", "userService", "subscriberService", "currencyService", "plansService"];
  angular.module('app.core').component('accountPlans', {
    controller: Controller,
    templateUrl: 'account-plans.html',
    bindings: {
      context: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(
    Plan,
    glAnalytics,
    glToast,
    userService,
    subscriberService,
    currencyService,
    plansService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isToolbar = isToolbar
    ctrl.isCurrencySwitch = isCurrencySwitch
    ctrl.isIntervalSwitch = isIntervalSwitch
    ctrl.setCurrency = setCurrency
    ctrl.setInterval = setInterval
    ctrl.select = select
    ctrl.isAvailable = isAvailable
    ctrl.isVisible = isVisible
    ctrl.orderByType = orderByType
    ctrl.orderByPrice = orderByPrice
    ctrl.getTrialText = getTrialText
    ctrl.getPrice1Text = getPrice1Text
    ctrl.getPrice2Text = getPrice2Text
    ctrl.getBasePriceText = getBasePriceText
    ctrl.getButtonClass = getButtonClass
    ctrl.getButtonLabel = getButtonLabel
    ctrl.canSelectPlan = canSelectPlan
    ctrl.isCurrent = isCurrent
    ctrl.canGetFreeTrial = canGetFreeTrial
    ctrl.somePlansHaveCredit = somePlansHaveCredit
    ctrl.FeaturesList = Plan.FeaturesList
    ctrl.shouldShowEnterpriseCTA = shouldShowEnterpriseCTA

    ctrl.typeOrders = {
      DRAFT: 0,
      LEGACY: 1,
      SAAS: 1,
      CUSTOM: 2,
    }

    ctrl.allFeatures = [
      {
        title: 'Invite Users',
        desc:
          'Add new users to your account (subject to the user limits of your plan)',
      },
      {
        title: 'Unlimited Projects',
        desc:
          'Create unlimited research projects on your account, and invite other users to collaborate in each project as required',
      },
      {
        title: 'Unlimited Surveys',
        desc:
          'Create unlimited surveys on your Glow account. No feature limits on creating simple or complex surveys - so much to explore!',
      },
      {
        title: 'Save Reports',
        desc:
          'Save public and private reports that are shared by other users on your Glow account',
      },
      {
        title: 'Online Analysis',
        desc:
          'Analyse your data instantly as responses arrive on your account, with tools that make it easy to accelerate your decision making',
      },
      {
        title: 'Interactive Data Sharing',
        desc:
          'Share rich interactive survey data with other people outside your account.',
      },
      {
        title: 'Export to CSV',
        desc:
          'Export the individual responses in text format (csv) or in numeric format (xlsx) for use in third-party tools',
      },
      {
        title: 'Integrated Consumer Panel',
        desc:
          "Use Glow's integrated panel to quickly connect to a worldwide audience of over 110 million people from quality, reliable sources. ",
      },
      {
        title: 'Clone surveys',
        desc:
          'No need to start from scratch when you can duplicate your surveys, import questions from other surveys, or drop in preset questions from your question library   ',
      },
      {
        title: 'Custom Analysis Branding',
        desc:
          'Modify the colours in your charts to fit your survey theme or align with your corporate branding. ',
      },
      {
        title: 'Automatic survey translation',
        desc:
          'Machine translate your survey into other languages or share the translation link with human translators for easy modification to support global distribution ',
      },
      {
        title: 'Project Files & Links',
        desc:
          'Upload key documents and links that you and your team need to manage your projects in Glow',
      },
      {
        title: 'Quota and Tag management',
        desc:
          'Control data tagging and the number of responses you capture in surveys',
      },
      {
        title: 'Quick Panel',
        desc:
          'Need data fast? Accelerate your project so you can get into the analysis quickly. Choose how long your survey is in field when using the integrated consumer panel',
      },
      {
        title: 'Panel Targeting',
        desc:
          'When using our integrated panel, default to nationally representative quotas for targeting respondents or pick your own target respondents quickly and easily',
      },
      {
        title: 'Survey channel redirects',
        desc:
          'Have a preferred panel partner? Work with any panel you like using third party panel redirects. ',
      },
      {
        title: 'Publish Reports',
        desc:
          'Share richer deliverables by combining resources into a packaged report you can store on your account. Share report links with your customers so they can store them on their accounts, too!',
      },
    ]

    function onInit() {
      glAnalytics.track('plans', 'display-plans', ctrl.context)
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSteadfast =
        ctrl.subscriber &&
        (ctrl.subscriber.subscriptionCode === 'steadfast' ||
          ctrl.subscriber.subscriptionCode === 'concierge')

      ctrl.isLoading = true
      plansService.list().then(function(plans) {
        // Check for next plan if any
        _.each(plans, function(plan) {
          if (
            ctrl.subscriber &&
            ctrl.subscriber.planDetail.nextPlanId === plan.id
          ) {
            ctrl.nextPlanName = plan.name
          }
        })
        ctrl.plans = plans
        ctrl.intervals = getIntervals()
        ctrl.currencies = getCurrencies()
        if (ctrl.subscriber) {
          ctrl.interval = ctrl.subscriber.plan.interval.type
          ctrl.currency = ctrl.subscriber.billing.currency
        }
        if (!ctrl.interval) {
          ctrl.interval = _.find(ctrl.intervals, { value: 'MONTH' })
            ? 'MONTH'
            : ctrl.intervals[0].value
        }
        if (!ctrl.currency) {
          ctrl.currency = _.find(ctrl.currencies, { value: 'AUD' })
            ? 'AUD'
            : ctrl.currencies[0].value
        }
        makeVisiblePlans(ctrl.plans)
        ctrl.isLoading = false
      })
    }

    function setCurrency(value) {
      ctrl.currency = value
      makeVisiblePlans(ctrl.plans)
    }

    function setInterval(value) {
      ctrl.interval = value
      makeVisiblePlans(ctrl.plans)
    }

    function makeVisiblePlans(plans) {
      plans = plans.filter(function(plan) {
        return isVisible(plan)
      })
      plans = _.orderBy(plans, function(plan) {
        return ctrl.typeOrders[plan.type]
      })
      plans = _.orderBy(plans, function(plan) {
        return plan.price.byCurrency(ctrl.currency).amount
      })
      ctrl.visiblePlans = plans
      buildFeatureList(ctrl.visiblePlans)
    }

    function buildFeatureList(plans) {
      for (var plan of plans) {
        console.log(plan)
      }
      ctrl.features = ctrl.FeaturesList.filter(function(feature, idx) {
        console.log(feature)

        // only display features that are differentiated across visible plans
        var values = plans.map(function(plan) {
          return plan.features[feature.field]
        })
        var isDifferentiated =
          !_.every(values, value => value === values[0]) || values.length === 1
        console.log(feature, values)
        return isDifferentiated
      })
    }

    function isToolbar() {
      return isCurrencySwitch() || isIntervalSwitch()
    }

    function isCurrencySwitch() {
      if (!ctrl.subscriber || !ctrl.subscriber.billing.currency) {
        return true
      }
    }

    function isIntervalSwitch() {
      return ctrl.intervals.length > 1
    }

    function isAvailable(plan) {
      var isSubscribersPlan =
        ctrl.subscriber && ctrl.subscriber.plan.id === plan.id
      var isAvailablePlan = plan.type === 'SAAS'
      if (ctrl.isSteadfast) {
        isAvailablePlan = plan.isSteadfast() && plan.isCustom()
      }
      if (ctrl.subscriber && ctrl.subscriber.subscriptionCode === 'concierge') {
        isAvailablePlan = false
      }
      return isSubscribersPlan || isAvailablePlan
    }

    function isVisible(plan) {
      var isInterval = true // plan.interval.type === ctrl.interval
      var hasCurrency = _.isNumber(plan.price.byCurrency(ctrl.currency).amount)
      return isAvailable(plan) && isInterval && hasCurrency
    }

    function orderByType(plan) {
      return ctrl.typeOrders[plan.type]
    }

    function orderByPrice(plan) {
      return plan.price.byCurrency(ctrl.currency).amount
    }

    function select(plan) {
      if (!ctrl.canSelectPlan(plan)) {
        return
      }
      if (userService.isSSR()) {
        return glToast.show("SSR users cannot update a subscriber's Plan")
      }

      var action = _.kebabCase('selected-' + plan.name)
      glAnalytics.track('plans', action, ctrl.context)
      ctrl.onSelect({ $plan: plan, $currency: ctrl.currency })
    }

    function getTrialText(plan) {
      if (plan.trialDays) {
        return plan.trialDays + ' days free*'
      }
      return ''
    }

    function getPrice1Text(plan) {
      if (plan.interval.type === 'MONTH') {
        var price = plan.price.byCurrency(ctrl.currency)
        var priceText = price.format({ decimal: false })
        return price.amount ? priceText + 'per month' : priceText
      } else if (plan.interval.type === 'YEAR') {
        var price = plan.price.byCurrency(ctrl.currency).clone()
        price.amount /= 12
        var priceText = price.format({ decimal: false })
        return price.amount ? priceText + ' per month' : priceText
      }
    }

    function getPrice2Text(plan) {
      var price = plan.price.byCurrency(ctrl.currency)
      var priceText = price.format({ decimal: false })
      if (!price.amount) return 'Free'
      if (plan.interval.type === 'MONTH') {
        return 'Billed monthly'
      } else if (plan.interval.type === 'YEAR') {
        return priceText + ' billed annually'
      }
    }

    function getBasePriceText(plan) {
      var price = plan.pricing.base.byCurrency(ctrl.currency)
      return price.format()
    }

    function getButtonClass(plan, index) {
      if (ctrl.subscriber) {
        return ctrl.subscriber.plan.id === plan.id ? '-color0' : '-color2'
      }
      if (index > 2) return '-color2'
      return '-color' + index
    }

    function getButtonLabel(plan) {
      if (!ctrl.subscriber) {
        return 'Select ' + plan.name
      }
      var currentPlan = ctrl.subscriber.plan
      if (currentPlan.id === plan.id) {
        return 'Current'
      }
      return 'Select'
    }

    function isCurrent(plan) {
      if (!ctrl.subscriber) {
        return false
      }
      return ctrl.subscriber.plan.id === plan.id
    }

    function canSelectPlan(plan) {
      if (!ctrl.subscriber) {
        return true
      }
      var currentPlan = ctrl.subscriber.plan
      var nextPlanId = ctrl.subscriber.planDetail.nextPlanId
      if (nextPlanId === plan.id) {
        return false
      }
      return currentPlan.id !== plan.id
    }

    function canGetFreeTrial(plan) {
      if (ctrl.subscriber) {
        return (
          plan.trialDays > 0 &&
          ctrl.subscriber.usage.trials === 0 &&
          ctrl.subscriber.plan.id !== plan.id
        )
      } else {
        return plan.trialDays > 0
      }
    }

    function shouldShowEnterpriseCTA() {
      if (!ctrl.subscriber) return true
      var currentPlan = _.find(ctrl.plans, function(plan) {
        return plan.id === ctrl.subscriber.plan.id
      })
      return !currentPlan.isCustom()
    }

    function getIntervals() {
      return Plan.intervals
        .filter(function(interval) {
          return _.some(ctrl.plans, function(plan) {
            return plan.interval.type === interval.type && isAvailable(plan)
          })
        })
        .map(function(interval) {
          return {
            label: interval.label,
            value: interval.type,
          }
        })
    }

    function getCurrencies() {
      return currencyService
        .getAll()
        .filter(function(currency) {
          return _.some(ctrl.plans, function(plan) {
            return (
              _.some(plan.price.list, { currency: currency.code }) &&
              isAvailable(plan)
            )
          })
        })
        .map(function(currency) {
          return {
            label: currency.code,
            value: currency.code,
          }
        })
    }

    function somePlansHaveCredit() {
      return _.some(ctrl.plans, function(plan) {
        return (
          isAvailable(plan) &&
          plan.cycleCredit.byCurrency(ctrl.currency).amount > 0
        )
      })
    }
  }
})()
