;(function() {
  'use strict'

  Controller.$inject = ["$state", "accountService"];
  angular.module('app.core').controller('Home', Controller)

  /* @ngInject */
  function Controller($state, accountService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getStarted = getStarted

    ctrl.companies = [
      { name: 'Target', imageUrl: '/img/trusted-target.png' },
      { name: 'Reckitt', imageUrl: '/img/trusted-reckitt.png' },
      { name: 'PWC', imageUrl: '/img/trusted-pwc.png' },
      { name: 'Puma', imageUrl: '/img/trusted-puma.png' },
      { name: 'Pepsi', imageUrl: '/img/trusted-pepsi.png' },
      { name: 'Gatorade', imageUrl: '/img/trusted-gatorade.png' },
      { name: 'Bain', imageUrl: '/img/trusted-bain.png' },
      { name: 'Special', imageUrl: '/img/trusted-special.png' },
      { name: 'Mars', imageUrl: '/img/trusted-mars.png' },
      { name: 'Ogilvy', imageUrl: '/img/trusted-ogilvy.png' },
      {
        name: 'Tourism Australia',
        imageUrl: '/img/trusted-tourism-australia.png',
      },
      { name: 'Leo Burnett', imageUrl: '/img/trusted-leo-burnett.png' },
      { name: 'Employment Hero', imageUrl: '/img/trusted-employment-hero.png' },
      { name: 'Nielson', imageUrl: '/img/trusted-nielson.png' },
      { name: 'Cadbury', imageUrl: '/img/trusted-cadbury.png' },
      { name: 'Schweppes', imageUrl: '/img/trusted-schweppes.png' },
      { name: 'Yellow', imageUrl: '/img/trusted-yellow.png' },
      { name: 'Who Gives A Crap', imageUrl: '/img/trusted-wgac.png' },
      { name: 'Telstra', imageUrl: '/img/trusted-telstra.png' },
    ]

    function onInit() {
      // ...
    }

    function getStarted(plan, currency) {
      accountService.showAuthDialog('signup').then(function(outcome) {
        // logged in? go to dashboard
        if (outcome === 'logged-in') {
          $state.go('dashboard')
        }
        // signed up?
        if (outcome === 'signed-up') {
          // if they didn't select a plan, show their
          // current plan + available upgrades
          if (!plan) {
            $state.go('dashboard')
            accountService.promptUpgrade({ context: 'welcome' })
            return
          }
          // free plan? dont show plans just go straight in
          if (plan.price.byCurrency('AUD').amount === 0) {
            $state.go('dashboard')
            // paid plan? prompt payment!
          } else {
            accountService
              .showPurchaseDialog({
                type: 'plan',
                currency: currency,
                plan: plan,
                context: 'welcome',
              })
              .finally(function() {
                // go to dashboard even if they cancel payment,
                // they will stay on the free plan!
                $state.go('dashboard')
              })
          }
        }
      })
    }
  }
})()
