;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$element", "$state", "$transitions", "glTips", "helpService", "userService", "subscriberService", "statsService", "pagesService", "subSelector", "orgSelector", "cloudinaryService", "accountService", "interactionsService", "publisherService"];
  angular.module('app.core').component('portalHeader', {
    controller: Controller,
    templateUrl: 'portal-header.html',
  })

  /* @ngInject */
  function Controller(
    $rootScope,
    $element,
    $state,
    $transitions,
    glTips,
    helpService,
    userService,
    subscriberService,
    statsService,
    pagesService,
    subSelector,
    orgSelector,
    cloudinaryService,
    accountService,
    interactionsService,
    publisherService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit

    ctrl.chat = chat
    ctrl.changeSub = changeSub
    ctrl.logout = userService.logout
    ctrl.showTips = glTips.resetCurrent
    ctrl.pageImageUrl = pageImageUrl
    ctrl.goToPage = goToPage
    ctrl.trigger = trigger
    ctrl.upgrade = upgrade
    ctrl.showAuthDialog = showAuthDialog
    ctrl.showSubscriberCreatorDialog =
      accountService.showSubscriberCreatorDialog

    function onInit() {
      $element.addClass('portal-header')
      $transitions.onSuccess(null, checkPageState)
      $rootScope.$on(subscriberService.Events.CHANGE, checkUser)

      checkUser()
    }

    function checkUser() {
      if (userService.isLoggedIn()) {
        ctrl.publishers = publisherService.publishers
        ctrl.user = userService.getUser()
        ctrl.subscriber = subscriberService.getSubscriber()
        ctrl.isSSR = userService.isSSR()
        ctrl.isAdmin = subscriberService.isAdmin(ctrl.user.id)
        // only show upgrade button when the plan is free and is NOT a custom plan eg. starter plan
        ctrl.showUpgrade =
          ctrl.subscriber.plan.isFree() &&
          !ctrl.subscriber.plan.isCustom() &&
          ctrl.isAdmin
        // hide the upgrade button if the subscriber is "concierge"
        // show the new org menu if subscriber is "concierge" and NOT SSR and is Admin
        if (ctrl.subscriber.subscriptionCode === 'concierge') {
          ctrl.showUpgrade = false
          ctrl.isNewOrgMenu = !ctrl.isSSR && ctrl.isAdmin
        }
        ctrl.isHelp = helpService.isSupported()
        ctrl.isAccountButton = ctrl.isSSR || ctrl.isAdmin
        // only allow inbox visibility if have interactions
        interactionsService
          .checkForInteractions(ctrl.subscriber.id)
          .then(function(hasInteractions) {
            ctrl.inboxVisible = hasInteractions
          })
        findAccountImage()
        loadStats()
        loadPages()
      } else {
        ctrl.publishers = null
        ctrl.user = null
        ctrl.subscriber = null
        ctrl.isSSR = false
        ctrl.showUpgrade = false
      }
    }

    function checkPageState() {
      if ($state.current.name === 'page.general') {
        ctrl.currentPage = _.find(ctrl.pages, { id: $state.params.pageId })
      } else {
        ctrl.currentPage = null
      }
    }

    function chat() {
      helpService.chat()
    }

    function findAccountImage() {
      var accountImageUrl = ctrl.subscriber.portalTheme.logoImageUrl
      if (accountImageUrl) {
        ctrl.accountImageStyle = {
          backgroundImage: 'url(' + accountImageUrl + ')',
        }
        ctrl.accountImageUrl = accountImageUrl
      }
    }

    function loadStats() {
      statsService.get().then(function(stats) {
        ctrl.stats = stats
        ctrl.hasInboxUnread =
          ctrl.stats.inboxUnread && ctrl.stats.inboxUnread > 0
      })
    }

    function loadPages() {
      pagesService.getAll().then(function(pages) {
        ctrl.pages = pages
        checkPageState()
      })
    }

    function goToPage(page) {
      // ctrl.currentPage = page;
      $state.go('page', { pageId: page.id })
    }

    function pageImageUrl(page) {
      return cloudinaryService.resize(page.logoUrl, 26, 26)
    }

    // used to trigger tip events
    function trigger(eventName) {
      $rootScope.$broadcast(eventName)
    }

    function changeSub() {
      var promise = ctrl.isSSR
        ? subSelector.select(true)
        : orgSelector.select(ctrl.user, true)
      promise.then(function() {
        var path = $state.href('dashboard')
        // minor delay to prevent dialog flicker
        setTimeout(function() {
          // hard reload the page to force re-login as
          // the new subscriber.
          if (window.location.pathname === path) {
            window.location.reload()
          } else {
            window.location.pathname = path
          }
        }, 300)
      })
    }

    function upgrade() {
      accountService.promptUpgrade({ context: 'header-button' })
    }

    function showAuthDialog(mode) {
      accountService.showAuthDialog(mode).then(function(outcome) {
        $state.reload()
        if (outcome === 'signed-up') {
          accountService.promptUpgrade({ context: 'welcome' })
        }
      })
    }
  }
})()
