;(function() {
  'use strict'

  Controller.$inject = ["$q", "$scope", "$rootScope", "$timeout", "$interval", "colors", "glUtils", "glDialog", "StateFactory", "ReportLink", "Question", "View", "Dashboard", "DashboardView", "ResponseFilter", "LocationSet", "subscriberService", "clipboardService", "configService", "userService", "channelService", "datapackService", "profileService", "themeService", "surveyExplorerExitService", "surveyReport", "reportQuestionExporter", "reportPPTXExporter", "surveyExplorerXLSXService"];
  angular.module('app.core').component('surveyExplorer', {
    controller: Controller,
    templateUrl: 'survey-explorer.html',
    bindings: {
      survey: '<',
      dashboard: '<',
      options: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    $scope,
    $rootScope,
    $timeout,
    $interval,
    colors,
    glUtils,
    glDialog,
    StateFactory,
    ReportLink,
    Question,
    View,
    Dashboard,
    DashboardView,
    ResponseFilter,
    LocationSet,
    subscriberService,
    clipboardService,
    configService,
    userService,
    channelService,
    datapackService,
    profileService,
    themeService,
    surveyExplorerExitService,
    surveyReport,
    reportQuestionExporter,
    reportPPTXExporter,
    surveyExplorerXLSXService
  ) {
    var ctrl = this

    var questionsById = {}

    ctrl.exporters = [
      { label: 'Workbook (xlsx)', type: 'workbook' },
      { label: 'All Responses (csv)', type: 'qcsv' },
      { label: 'All Responses (xlsx)', type: 'qxlsx' },
      { label: 'All Responses (spss-xlsx)', type: 'qspss' },
      // { label: 'Excel Workbook (xlsx)', type: 'xlsx' },
      // { label: 'PowerPoint (pptx)', type: 'pptx' },
    ]

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.isMobile = isMobile
    ctrl.isSidebarOverlay = isSidebarOverlay
    ctrl.isSidebarOpen = isSidebarOpen
    ctrl.toggleSidebar = toggleSidebar
    ctrl.toggleSwitcher = toggleSwitcher
    ctrl.switchDashboard = switchDashboard
    ctrl.duplicateDashboard = duplicateDashboard
    ctrl.destroyDashboard = destroyDashboard
    ctrl.addDashboard = addDashboard
    ctrl.showExcludedViewsDialog = showExcludedViewsDialog
    ctrl.setTab = setTab
    ctrl.setView = setView
    ctrl.toggleMenu = toggleMenu
    ctrl.moveView = moveView
    ctrl.duplicateView = duplicateView
    ctrl.togglePrivateView = togglePrivateView
    ctrl.removeView = removeView
    ctrl.isSlot = isSlot
    ctrl.getSlotOptions = getSlotOptions
    ctrl.getSlotValue = getSlotValue
    ctrl.setSlotValue = setSlotValue
    ctrl.isSlotConfig = isSlotConfig
    ctrl.editSlotConfig = editSlotConfig
    ctrl.getAllPrefilters = getAllPrefilters
    ctrl.isGlobalPrefilter = isGlobalPrefilter
    ctrl.isLocalPrefilter = isLocalPrefilter
    ctrl.createFilter = createFilter
    ctrl.editFilter = editFilter
    ctrl.moveFilter = moveFilter
    ctrl.duplicateFilter = duplicateFilter
    ctrl.togglePrivateFilter = togglePrivateFilter
    ctrl.deleteFilter = deleteFilter
    ctrl.removePrefilter = removePrefilter
    ctrl.addPrefilters = addPrefilters
    ctrl.addViewFilters = addViewFilters
    ctrl.removeViewFilter = removeViewFilter
    ctrl.hasChart = hasChart
    ctrl.hasTable = hasTable
    ctrl.hasList = hasList
    ctrl.hasCloud = hasCloud
    ctrl.hasImages = hasImages
    ctrl.hasMap = hasMap
    ctrl.queryList = _.debounce(queryList, 200, {
      trailing: true,
    })
    ctrl.editTheme = editTheme
    ctrl.exportAs = exportAs
    ctrl.toggleChartStacked = toggleChartStacked
    ctrl.toggleChartRotate = toggleChartRotate
    ctrl.toggleChartAbsolute = toggleChartAbsolute
    ctrl.toggleTableAbsolute = toggleTableAbsolute
    ctrl.editSettings = editSettings
    ctrl.exitBeta = exitBeta
    ctrl.copyShareLink = copyShareLink
    ctrl.save = save
    ctrl.onCanvasInit = onCanvasInit
    ctrl.exportDashboardWorkbook = exportDashboardWorkbook

    function onInit() {
      window.ctrl = ctrl
      ctrl.showSidebar = !isMobile()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()
      ctrl.options = ctrl.options || {}
      ctrl.isShared = ctrl.options.isShared || false
      ctrl.tab = 'views'
      ctrl.state = new StateFactory([
        'building',
        'loading',
        'ready',
        'noResponses',
        'error',
      ])
      for (var view of ctrl.survey.views) {
        if (view.type === View.Types.QUESTION) {
          var question = view.value
          questionsById[question.id] = question
        }
      }
      // if a dashboard was supplied, we're probs looking at a shared dashboard
      if (ctrl.dashboard) {
        console.log('viewing shared dashboard')
        ctrl.state.loading()
        ctrl.isShared = true
        ctrl.dashboards = [ctrl.dashboard]
        var dashboard = ctrl.dashboard
        ctrl.dashboard = null // allow it to be selected
        return loadProfile()
          .then(function() {
            return datapackService
              .getDatapack(ctrl.survey)
              .then(function(datapack) {
                ctrl.datapack = datapack
                return datapackService
                  .getCSV(ctrl.survey.id)
                  .then(function(csv) {
                    ctrl.csv = csv
                    setupDatapack()
                    selectDashboard(dashboard)
                    return loadChannels().then(function() {
                      ctrl.state.ready()
                    })
                  })
              })
          })
          .catch(function(err) {
            console.error(err)
            ctrl.state.error()
          })
      } else {
        console.log('viewing all dashboards')
        ctrl.state.loading()
        loadProfile()
          .then(function() {
            ensureDatapack().then(function() {
              return datapackService
                .getDatapack(ctrl.survey)
                .then(function(datapack) {
                  ctrl.datapack = datapack
                  return datapackService
                    .getCSV(ctrl.survey.id)
                    .then(function(csv) {
                      ctrl.csv = csv
                      setupDatapack()
                      return datapackService
                        .listDashboards(ctrl.survey.id)
                        .then(function(dashboards) {
                          ctrl.dashboards = dashboards
                          return initDashboard().then(function() {
                            return loadChannels().then(function() {
                              ctrl.state.ready()
                              watchUnsaved(true)
                            })
                          })
                        })
                    })
                })
                .catch(function(err) {
                  console.error(err)
                  ctrl.state.error()
                })
            })
          })
          .catch(function(err) {
            console.error(err)
            ctrl.state.error()
          })
      }
    }

    function ensureDatapack() {
      if (ctrl.survey.hasDatapack) {
        return $q.resolve()
      }
      ctrl.state.building()
      return datapackService.createDatapack(ctrl.survey.id)
    }

    function initDashboard() {
      if (ctrl.dashboards.length) {
        return datapackService
          .getDashboard(ctrl.survey, ctrl.dashboards[0].id)
          .then(function(dashboard) {
            selectDashboard(dashboard)
          })
      }
      addDashboard('New Dashboard')
      return $q.resolve()
    }

    function loadProfile() {
      return profileService.get(ctrl.survey.ownerId).then(function(profile) {
        ctrl.profile = profile
      })
    }

    function loadChannels() {
      if (ctrl.isShared) {
        ctrl.channels = []
        console.error('TODO: need to get channels publicly?')
        return $q.resolve()
      }

      return channelService
        .getBySurvey(ctrl.survey.id, null, {
          // token: survey.options.shareToken,
        })
        .then(function(channels) {
          ctrl.channels = channels
        })
    }

    function isMobile() {
      return document.body.offsetWidth <= 1024
    }

    function isSidebarOverlay() {
      return isMobile() ? ctrl.showSidebar : false
    }

    function isSidebarOpen() {
      return !isMobile()
    }

    function toggleSidebar() {
      ctrl.showSidebar = !ctrl.showSidebar
    }

    function toggleSwitcher(e) {
      if (e.defaultPrevented) return
      e.isSwitcher = true
      ctrl.switcherOpen = !ctrl.switcherOpen
      if (ctrl.switcherOpen) {
        document.addEventListener('click', onSwitcherDocumentClick)
      } else {
        document.removeEventListener('click', onSwitcherDocumentClick)
      }
    }

    function onSwitcherDocumentClick(e) {
      if (e.isSwitcher) return
      $scope.$applyAsync(function() {
        ctrl.switcherOpen = false
      })
    }

    function switchDashboard(id) {
      if (ctrl.dashboard.id === id) return
      if (!surveyExplorerExitService.check()) return
      if (ctrl.dashboard.isNew) {
        // discard new and unsaved
        ctrl.dashboards = ctrl.dashboards.filter(function(d) {
          return d.id !== ctrl.dashboard.id
        })
      }
      var listIdx = ctrl.dashboards.findIndex(function(d) {
        return d.id === id
      })
      var listItem = ctrl.dashboards[listIdx]
      // if we already have the full dashboard in our list use that so switching is instant
      if (listItem.views) {
        selectDashboard(listItem)
      }
      // otherwise we need to fetch it
      if (!listItem.views) {
        datapackService.getDashboard(ctrl.survey, id).then(function(dashboard) {
          // replace the list item stub
          ctrl.dashboards[listIdx] = dashboard
          // select it
          selectDashboard(dashboard)
        })
      }
    }

    function duplicateDashboard() {
      var dashboard = ctrl.dashboard.clone().refresh()
      if (!dashboard.name.endsWith(' (Copy)')) {
        dashboard.name = dashboard.name + ' (Copy)'
      }
      dashboard.commit()
      ctrl.dashboards.push(dashboard)
      selectDashboard(dashboard)
    }

    function destroyDashboard() {
      var dashboard = ctrl.dashboard
      var idx = ctrl.dashboards.findIndex(function(d) {
        return d.id === dashboard.id
      })
      var nextDashboard = ctrl.dashboards[idx + 1]
      if (!nextDashboard) nextDashboard = ctrl.dashboards[idx - 1]
      ctrl.dashboards = ctrl.dashboards.filter(function(d) {
        return d.id !== dashboard.id
      })
      if (!dashboard.isNew) {
        datapackService.deleteDashboard(dashboard)
      }
      if (nextDashboard) {
        switchDashboard(nextDashboard.id)
      } else {
        addDashboard('New Dashboard')
      }
    }

    function addDashboard(name) {
      if (!surveyExplorerExitService.check()) return
      // create in-memory dashboard
      var dashboard = new Dashboard(ctrl.survey)
      dashboard.name = name

      // create an "All" filter (completes, exits, overquotas)
      var filter = new ResponseFilter(ctrl.survey)
      filter.name = 'All'
      var group = filter.addGroup()
      var rule = group.addRule()
      rule.setType(rule.Types.KIND)
      rule.kinds.push('COMPLETE')
      rule.kinds.push('EXIT')
      rule.kinds.push('OVERQUOTA')
      dashboard.filters.push(filter)
      // ctrl.dashboard.prefilters.push(filter.id)

      // add views for each question
      var completesFilter = dashboard.filters.find(function(filter) {
        return filter.name === 'All'
      })
      var views = []
      _.each(ctrl.survey.views, function(view) {
        if (view.type === View.Types.QUESTION) {
          if (view.value.isHidden) return
          var view = new DashboardView().fromQuestion(view.value)
          view.configs.filters.items.push(completesFilter.id)
          views.push(view)
        }
      })
      dashboard.views = views

      // console.log('survey', ctrl.survey)
      // console.log('datapack', ctrl.datapack)
      // console.log('csv', ctrl.csv)
      // console.log('dashboard', ctrl.dashboard)

      dashboard.commit()
      ctrl.dashboards.push(dashboard)

      selectDashboard(dashboard)
    }

    function selectDashboard(dashboard) {
      if (ctrl.dashboard === dashboard) return

      var prevActiveQuestionId = ctrl.view && ctrl.view.questionId

      if (ctrl.dashboard) {
        if (ctrl.dashboard.isNew) {
          ctrl.dashboards = ctrl.dashboards.filter(function(d) {
            return d.id !== ctrl.dashboard.id
          })
        } else {
          ctrl.dashboard.revert()
          syncDashboard(ctrl.dashboard)
        }
      }

      ctrl.dashboard = dashboard

      // map filters by id for easy lookup
      ctrl.filtersById = {}
      for (const filter of ctrl.dashboard.filters) {
        ctrl.filtersById[filter.id] = filter
      }

      // initialize filter matchers
      for (var filter of dashboard.filters) {
        filter.match = filter.toDatapackMatcher(ctrl.datapack)
      }

      var view
      if (prevActiveQuestionId) {
        view = ctrl.dashboard.views.find(function(v) {
          return v.questionId === prevActiveQuestionId
        })
      }
      if (!view) {
        view = ctrl.dashboard.views.find(function(view) {
          return ctrl.isShared ? !view.private : true
        })
      }
      setView(null, view)

      updateExcludedViews()
      updateRestricted()

      watchUnsaved(true)
    }

    function syncDashboard(dashboard, oldId) {
      var listDashboard = ctrl.dashboards.find(function(d) {
        return d.id === oldId || d.id === dashboard.id
      })
      if (listDashboard) {
        listDashboard.id = dashboard.id
        listDashboard.name = dashboard.name
      }
    }

    function setupDatapack() {
      // sometimes csv has an extra empty row?
      if (ctrl.csv[ctrl.csv.length - 1][0] === '') {
        ctrl.csv.pop()
        console.log('removed empty row')
      }

      // remove deleted completely (later we may support this)
      var deletedIdx = ctrl.datapack.mappings.columns.isDeleted
      ctrl.csv = ctrl.csv.filter(function(row) {
        return row[deletedIdx] !== '1'
      })

      // TODO: remove this after BE uses 0 column array indices
      // for (const key in ctrl.datapack.mappings.columns) {
      //   ctrl.datapack.mappings.columns[key]--
      // }

      // repopulate non-column mapping values so its easier to work with
      for (const type in ctrl.datapack.mappings) {
        if (type === 'columns') continue
        var idx = ctrl.datapack.mappings.columns[type]
        for (const row of ctrl.csv) {
          var key = row[idx]
          var value = ctrl.datapack.mappings[type][key]
          row[idx] = value
        }
      }

      // use moment for createdAt dates
      var createdAtIdx = ctrl.datapack.mappings.columns.createdAt
      for (const row of ctrl.csv) {
        var value = parseInt(row[createdAtIdx])
        var date = moment(value)
        row[createdAtIdx] = date
      }

      // create our "completesOnly" filter
      {
        var filter = new ResponseFilter(ctrl.survey)
        filter.name = 'Completes'
        var group = filter.addGroup()
        var rule = group.addRule()
        rule.setType(rule.Types.KIND)
        rule.kinds.push('COMPLETE')
        filter.match = filter.toDatapackMatcher(ctrl.datapack)
        ctrl.completesOnlyFilter = filter
      }
    }

    function updateRestricted() {
      // when viewing a shared dashboard, any filters that utilize an excluded/private view need to be marked.
      // existing filters that use any of these views are not editable.
      // new filters created will not be able to select these views.
      var restrictedQuestionIds = new Set()
      for (var v of ctrl.survey.views) {
        if (v.type !== View.Types.QUESTION) continue
        var question = v.value
        var view = ctrl.dashboard.views.find(function(v) {
          return v.questionId === question.id
        })
        if (!view || view.private) {
          restrictedQuestionIds.add(question.id)
          question.isDashboardRestricted = ctrl.isShared // only hide from filter UI when viewing shared
        } else {
          question.isDashboardRestricted = false
        }
      }
      // mark all filters that utilize a restricted view
      for (const filter of ctrl.dashboard.filters) {
        filter.restricted = false
        for (const group of filter.groups) {
          for (const rule of group.rules) {
            var restricted =
              restrictedQuestionIds.has(rule.questionId) ||
              _.some(rule.questions.items, function(qid) {
                return restrictedQuestionIds.has(qid)
              })
            if (restricted) {
              filter.restricted = true
            }
          }
        }
      }
    }

    function updateExcludedViews() {
      ctrl.excludedViews = []
      for (const view of ctrl.survey.views) {
        if (view.type !== View.Types.QUESTION) continue
        var question = view.value
        var idx = ctrl.dashboard.views.findIndex(function(v) {
          return v.questionId === question.id
        })
        if (idx === -1) {
          ctrl.excludedViews.push({
            type: View.Types.QUESTION,
            id: question.id,
            label: 'Q' + question.getNumber() + '. ' + question.title,
          })
        }
      }
    }

    function restoreExcludedView(type, id) {
      var dashboard = ctrl.dashboard
      var question = ctrl.survey.views.find(function(v) {
        return v.type === type && v.value.id === id
      }).value
      var view = new DashboardView().fromQuestion(question)
      var completesFilter = dashboard.filters.find(function(filter) {
        return filter.name === 'All'
      })
      if (completesFilter) {
        // they may have deleted it kek
        view.configs.filters.items.push(completesFilter.id)
      }
      dashboard.views.unshift(view)
      updateExcludedViews()
      setView(null, view)
    }

    function showExcludedViewsDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-excluded-views-dialog__dialog">',
          '<survey-explorer-excluded-views-dialog ',
            'excluded-views="excludedViews"',
            'on-restore="dialog.close($item)" ',
            'on-close="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          excludedViews: ctrl.excludedViews,
        },
      }
      return glDialog.show(options).then(function(item) {
        restoreExcludedView(item.type, item.id)
      })
    }

    function watchUnsaved(enabled) {
      if (ctrl.isShared) return
      if (!enabled) {
        $interval.cancel(ctrl.unsavedIntervalId)
        ctrl.isUnsaved = false
        surveyExplorerExitService.setEnabled(false)
        return
      }
      function check() {
        ctrl.isUnsaved = ctrl.dashboard.isUnsaved()
        // var data = JSON.stringify(ctrl.dashboard.serialize(true))
        // ctrl.isUnsaved = ctrl.lastSavedData !== data
        surveyExplorerExitService.setEnabled(ctrl.isUnsaved)
      }
      check()
      if (ctrl.unsavedIntervalId) {
        $interval.cancel(ctrl.unsavedIntervalId)
      }
      // ctrl.isUnsaved = false
      // ctrl.lastSavedData = JSON.stringify(ctrl.dashboard.serialize(true))
      ctrl.unsavedIntervalId = $interval(check, 1000)
    }

    function setTab(tab) {
      ctrl.tab = tab
    }

    function setView(e, view) {
      if (e && e._menu) return

      ctrl.view = view

      if (view.type === 'question') {
        ctrl.question = questionsById[view.questionId]
      }

      ctrl.slotOptions = {}
      ctrl.slotOptions.loop = view.loops
      ctrl.slotOptions.target = []
      if (view.statements.length || view.ranks.length) {
        ctrl.slotOptions.target.push({
          id: null,
          label: '-',
        })
        for (var statement of view.statements) {
          ctrl.slotOptions.target.push({
            id: statement.id,
            label: statement.label,
            type: 'statement',
          })
        }
        for (var choice of view.choices) {
          ctrl.slotOptions.target.push({
            id: choice.id,
            label: choice.label,
            type: 'choice',
          })
        }
        for (var rank of view.ranks) {
          ctrl.slotOptions.target.push({
            id: rank.id,
            label: rank.label,
            type: 'rank',
          })
        }
      }
      ctrl.slotOptions.rows = [
        // {
        //   id: 'loops',
        //   label: 'Loops',
        //   hidden: !view.loops.length,
        // },
        {
          id: 'choices',
          label: 'Choices',
        },
        {
          id: 'statements',
          label: 'Statements',
          hidden: !view.statements.length,
        },
        {
          id: 'ranks',
          label: 'Ranks',
          hidden: !view.ranks.length,
        },
        {
          id: 'filters',
          label: 'Filters',
        },
      ]
      ctrl.slotOptions.columns = [
        // {
        //   id: 'loops',
        //   label: 'Loops',
        //   hidden: !view.loops.length,
        // },
        {
          id: 'choices',
          label: 'Choices',
        },
        {
          id: 'statements',
          label: 'Statements',
          hidden: !view.statements.length,
        },
        {
          id: 'ranks',
          label: 'Ranks',
          hidden: !view.ranks.length,
        },
        {
          id: 'filters',
          label: 'Filters',
        },
      ]
      ctrl.slotOptions.choice = view.choices
      ctrl.slotOptions.statement = view.statements
      ctrl.slotOptions.rank = view.ranks

      var hasSum =
        ctrl.question.type === Question.Types.CONSTANT_SUM ||
        view.configs.choices.combineWeighted

      ctrl.slotOptions.format = [
        {
          id: 'count',
          label: 'Count',
          hidden: false,
        },
        {
          id: 'sum',
          label: 'Sum',
          hidden: false, // !hasSum,
        },
        {
          id: 'average',
          label: 'Average',
          hidden: false, // !hasSum,
        },
        {
          id: 'row-percent',
          label: 'Row %',
          hidden: false,
        },
        {
          id: 'col-percent',
          label: 'Column %',
          hidden: false,
        },
        {
          id: 'total-percent',
          label: 'Total %',
          hidden: false,
        },
      ]

      // ctrl.chartRebuild = true

      build()
    }

    function toggleMenu(item) {
      if (ctrl.menu === item) {
        ctrl.menu = null
      } else {
        ctrl.menu = item
      }
      if (ctrl.menu && !ctrl.menuBound) {
        document.addEventListener('click', onWindowMenu)
        ctrl.menuBound = true
      }
      if (!ctrl.menu && ctrl.menuBound) {
        document.removeEventListener('click', onWindowMenu)
        ctrl.menuBound = false
      }
    }

    function onWindowMenu(e) {
      if (e._menu) return
      $scope.$applyAsync(function() {
        toggleMenu(null)
      })
    }

    function moveView(view, offset) {
      var fromIdx = ctrl.dashboard.views.indexOf(view)
      var toIdx = fromIdx + offset
      var views = ctrl.dashboard.views
      if (toIdx < 0 || toIdx > views.length) return
      views.splice(toIdx, 0, views.splice(fromIdx, 1)[0])
    }

    function duplicateView(view) {
      toggleMenu(null)
      var idx = ctrl.dashboard.views.indexOf(view)
      var newView = view.duplicate()
      ctrl.dashboard.views.splice(idx + 1, 0, newView)
    }

    function togglePrivateView(view) {
      view.private = !view.private
    }

    function removeView(view) {
      toggleMenu(null)
      var idx = ctrl.dashboard.views.indexOf(view)
      if (ctrl.view === view) {
        var nextIdx =
          idx === ctrl.dashboard.views.length - 1 ? idx - 1 : idx + 1
        setView(null, ctrl.dashboard.views[nextIdx])
      }
      ctrl.dashboard.views.splice(idx, 1)
      updateExcludedViews()
    }

    function build() {
      buildData()
      if (hasTable(ctrl.view)) {
        buildTable()
      }
      if (hasChart(ctrl.view)) {
        buildChart()
      }
      if (hasList(ctrl.view)) {
        buildList()
      }
      if (hasCloud(ctrl.view)) {
        buildCloud()
      }
      if (hasImages(ctrl.view)) {
        buildImages()
      }
      if (hasMap(ctrl.view)) {
        buildMap()
      }
    }

    function buildData() {
      console.time('buildData')
      var view = ctrl.view
      var prefilters = getAllPrefilters()
      var csv = ctrl.csv.filter(function(row) {
        return _.every(prefilters, function(filter) {
          return filter.match(row)
        })
      })
      // the completesOnly setting (default) can be switched off to include exits & overquotas too
      if (ctrl.dashboard.completesOnly) {
        csv = csv.filter(function(row) {
          return ctrl.completesOnlyFilter.match(row)
        })
      }
      ctrl.totalCount = csv.length
      ctrl.data = {
        // rows: {
        //   type: 'choice',
        //   items: [{ color, value: choice, count }],
        // },
        // cols: {
        //   type: 'filter',
        //   items: [{ color, value: filter, count }],
        // },
        // matrix: [
        //   [{ count: 12, rowPercent: 0.5, colPerc: 1 }],
        // ],
        // count: 0, // answered
      }
      var questionId = view.questionId
      var questionType = view.questionType
      var loop = view.slots.loop || '-'
      var rows = view.slots.rows
      var cols = view.slots.columns
      var tuple = [rows, cols]

      ctrl.theme = themeService.resolveTheme(
        view.theme,
        ctrl.profile.themeContext,
        ctrl.dashboard.theme
      )

      function getActiveFilters() {
        var filters = view.configs.filters.items.map(function(filterId) {
          return ctrl.filtersById[filterId]
        })
        if (ctrl.isShared) {
          filters = filters.filter(function(filter) {
            return !filter.private
          })
        }
        return filters
      }

      function countRows(match) {
        var n = 0
        for (var row of csv) {
          if (match(row)) n++
        }
        return n
      }

      function collectRespondents(match) {
        var set = new Set()
        for (var row of csv) {
          if (match(row)) {
            var respondentId = row[ctrl.datapack.mappings.columns.id]
            set.add(respondentId)
          }
        }
        return set
      }

      function makeCell(match) {
        var count = 0
        var sum = 0
        for (var row of csv) {
          var value = match(row)
          if (value) count++
          sum += value
        }
        return {
          count: count,
          sum: sum,
          weight: null,
          average: null,
          rowPercent: null,
          colPercent: null,
          totalPercent: null,
        }
      }

      function finalize() {
        // answered counts across each row and column
        ctrl.data.rows.items.forEach(function(row) {
          // this row is the primary set
          var primary = row.respondents
          // the combined set of all the columns are the secondary set
          var secondaries = new Set()
          ctrl.data.cols.items.forEach(function(col) {
            for (const id of col.respondents) {
              secondaries.add(id)
            }
          })
          // intersection is respondents in both!
          row.count = primary.intersection(secondaries).size
        })
        ctrl.data.cols.items.forEach(function(col) {
          // this col is the primary set
          var primary = col.respondents
          // the combined set of all the rows are the secondary set
          var secondaries = new Set()
          ctrl.data.rows.items.forEach(function(row) {
            for (const id of row.respondents) {
              secondaries.add(id)
            }
          })
          // intersection is respondents in both!
          col.count = primary.intersection(secondaries).size
        })

        // now do cell values
        var matrix = ctrl.data.matrix
        var numRows = matrix.length
        var numCols = matrix.length ? matrix[0].length : 0
        for (let r = 0; r < numRows; r++) {
          for (let c = 0; c < numCols; c++) {
            var cell = matrix[r][c]
            cell.average = cell.sum / cell.count // ctrl.data.count || 0 // NaN fallback
            cell.rowPercent = cell.count / ctrl.data.rows.items[r].count || 0 // NaN fallback
            cell.colPercent = cell.count / ctrl.data.cols.items[c].count || 0 // NaN fallback
            cell.totalPercent = cell.count / ctrl.data.count || 0 // NaN fallback
            console.log('cell', cell)
          }
        }

        removeHidden()
      }

      function flipMatrix() {
        var matrix = ctrl.data.matrix
        if (!matrix.length) return
        let flipped = []
        for (let col = 0; col < matrix[0].length; col++) {
          let newRow = []
          for (let row = 0; row < matrix.length; row++) {
            newRow.push(matrix[row][col])
          }
          flipped.push(newRow)
        }
        ctrl.data.matrix = flipped
      }

      function flip() {
        flipMatrix()
        var newCols = ctrl.data.rows
        var newRows = ctrl.data.cols
        ctrl.data.rows = newRows
        ctrl.data.cols = newCols
      }

      function removeHidden() {
        // removes via redact=hide
        // the numbers are calculated with them in, but theyre removed after.
        // note: we iterate backwards since we will be removing things
        for (let i = ctrl.data.cols.items.length - 1; i >= 0; i--) {
          const item = ctrl.data.cols.items[i]
          if (item.value.redact === 'hide') {
            // remove item
            ctrl.data.cols.items.splice(i, 1)
            // remove from matrix
            for (const row of ctrl.data.matrix) {
              row.splice(i, 1)
            }
          }
        }
        // same for rows
        for (let i = ctrl.data.rows.items.length - 1; i >= 0; i--) {
          const item = ctrl.data.rows.items[i]
          if (item.value.redact === 'hide') {
            // remove item
            ctrl.data.rows.items.splice(i, 1)
            // remove from matrix
            ctrl.data.matrix.splice(i, 1)
          }
        }
      }

      // total count (answered)
      {
        var questionId = view.questionId
        var key = `q_${questionId}_l_${loop}_answered`
        var idx = ctrl.datapack.mappings.columns[key]
        ctrl.data.count = countRows(function(row) {
          return ~~row[idx]
        })
      }

      // ensure merged choices are represented in a csv column
      function ensureMergeCol(choices, statementIds) {
        // if (
        //   tuple.includes('choices') &&
        //   [
        //     Question.Types.CHOICE,
        //     Question.Types.MATRIX,
        //     Question.Types.SCALE,
        //     Question.Types.NPS,
        //     Question.Types.MOOD,
        //     Question.Types.RATING,
        //   ].includes(view.questionType)
        // ) {
        function ensure(choice, statementId) {
          const key = statementId
            ? `q_${questionId}_l_${loop}_s_${statementId}_c_${choice.id}_selected`
            : `q_${questionId}_l_${loop}_c_${choice.id}_selected`
          if (!ctrl.datapack.mappings.columns.hasOwnProperty(key)) {
            const idx = ++ctrl.datapack.refCounts.columns
            ctrl.datapack.mappings.columns[key] = idx
            // NOTE: we're updating the entire csv here, not just the subset!
            for (const row of ctrl.csv) {
              let anySelected
              for (const optionId of choice.optionIds) {
                const _key = statementId
                  ? `q_${questionId}_l_${loop}_s_${statementId}_c_${optionId}_selected`
                  : `q_${questionId}_l_${loop}_c_${optionId}_selected`
                const _idx = ctrl.datapack.mappings.columns[_key]
                const _selected = row[_idx] === '1'
                if (_selected) anySelected = true
              }
              row[idx] = anySelected ? '1' : '0'
            }
          }
        }
        for (const choice of choices) {
          if (choice.optionIds.length > 1) {
            if (statementIds && statementIds.length) {
              for (const statementId of statementIds) {
                ensure(choice, statementId)
              }
            } else {
              ensure(choice)
            }
          }
        }
        // }
      }

      function processCombinedWeighted(choicesAreRows) {
        if (view.configs.choices.combineWeighted) {
          var matrix = ctrl.data.matrix
          var row = []
          var allRespondents = new Set()
          for (let c = 0; c < matrix[0].length; c++) {
            let totalWeightedSum = 0
            let count = 0
            for (let r = 0; r < matrix.length; r++) {
              var cell = matrix[r][c]
              totalWeightedSum += cell.count * cell.weight
              count += cell.count
            }
            // var weightedAvg = totalWeightedSum / count
            row.push({
              count: count,
              sum: totalWeightedSum,
              // ...rest is calculated via finalize()
            })
          }
          var allRespondents = new Set()
          for (var item of ctrl.data.rows.items) {
            for (const respondentId of item.respondents) {
              allRespondents.add(respondentId)
            }
          }
          ctrl.data.matrix = [row]
          ctrl.data.rows = {
            type: 'choice',
            items: [
              {
                color: getStandardColor(0),
                value: {
                  label: view.configs.choices.combineWeightedLabel || 'Total',
                },
                count: ctrl.data.count,
                respondents: allRespondents,
              },
            ],
          }
        }
      }

      //
      // (choices x filters) + ?statement|rank
      //

      if (tuple.includes('choices') && tuple.includes('filters')) {
        var choices = view.configs.choices.items.filter(function(choice) {
          return choice.redact !== 'exclude'
        })
        var filters = getActiveFilters()
        var statementId = view.statements.length ? view.slots.target : null
        var rankId = view.ranks.length ? view.slots.target : null
        var matrix = []
        ensureMergeCol(choices, [statementId])
        // rows
        {
          var linear = hasLinearChoiceColors(questionId)
          ctrl.data.rows = {
            type: 'choice',
            items: choices.map(function(choice, choiceIdx) {
              var key
              var numericId = false
              if (questionType === Question.Types.NUMERIC) {
                key = `q_${questionId}_l_${loop}_number`
                numericId = choice.id
              } else if (questionType === Question.Types.CONSTANT_SUM) {
                key = statementId
                  ? `q_${questionId}_l_${loop}_s_${statementId}_c_${choice.id}_number`
                  : `q_${questionId}_l_${loop}_c_${choice.id}_number`
              } else {
                key = statementId
                  ? `q_${questionId}_l_${loop}_s_${statementId}_c_${choice.id}_selected`
                  : `q_${questionId}_l_${loop}_c_${choice.id}_selected`
              }
              var idx = ctrl.datapack.mappings.columns[key]
              var rankIdx
              if (rankId) {
                var key = `q_${questionId}_l_${loop}_c_${choice.id}_rank`
                rankIdx = ctrl.datapack.mappings.columns[key]
              }
              return {
                color: linear ? getLinearColor(choiceIdx, choices.length) : getStandardColor(choiceIdx), // prettier-ignore
                value: choice,
                count: 0,
                respondents: collectRespondents(function(row) {
                  if (numericId !== false) {
                    return row[idx] === numericId ? 1 : 0
                  } else if (rankId) {
                    return row[rankIdx] === rankId ? 1 : 0
                  } else {
                    return ~~row[idx]
                  }
                }),
              }
            }),
          }
        }
        // cols
        {
          ctrl.data.cols = {
            type: 'filter',
            items: filters.map(function(filter, filterIdx) {
              return {
                color: getStandardColor(filterIdx),
                value: filter,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return filter.match(row)
                }),
              }
            }),
          }
        }
        // cells
        for (var choice of choices) {
          var row = []
          var key
          var numericId = false
          if (questionType === Question.Types.NUMERIC) {
            key = `q_${questionId}_l_${loop}_number`
            numericId = choice.id
          } else if (questionType === Question.Types.CONSTANT_SUM) {
            key = statementId
              ? `q_${questionId}_l_${loop}_s_${statementId}_c_${choice.id}_number`
              : `q_${questionId}_l_${loop}_c_${choice.id}_number`
          } else {
            key = statementId
              ? `q_${questionId}_l_${loop}_s_${statementId}_c_${choice.id}_selected`
              : `q_${questionId}_l_${loop}_c_${choice.id}_selected`
          }
          var idx = ctrl.datapack.mappings.columns[key]
          var rankIdx
          if (rankId) {
            var key = `q_${questionId}_l_${loop}_c_${choice.id}_rank`
            rankIdx = ctrl.datapack.mappings.columns[key]
          }
          for (var filter of filters) {
            var cell = makeCell(function(row) {
              var filterMatch = filter.match(row)
              if (!filterMatch) return 0
              if (numericId !== false) {
                return row[idx] === numericId ? 1 : 0
              } else if (rankId) {
                return row[rankIdx] === rankId ? 1 : 0
              } else {
                return ~~row[idx]
              }
            })
            cell.weight = choice.weight
            row.push(cell)
          }
          matrix.push(row)
        }
        ctrl.data.matrix = matrix
        processCombinedWeighted(true)
        if (rows === 'filters') {
          flip()
        }
        finalize()
      }

      //
      // (filters x statements) + choice
      //

      if (tuple.includes('filters') && tuple.includes('statements')) {
        var filters = getActiveFilters()
        var statements = view.configs.statements.items.filter(function(
          statement
        ) {
          return statement.redact !== 'exclude'
        })
        var choices = view.configs.choices.items
        var choiceId = view.slots.target
        var matrix = []
        ensureMergeCol(
          choices,
          statements.map(function(s) {
            return s.id
          })
        )
        // rows
        {
          ctrl.data.rows = {
            type: 'filter',
            items: filters.map(function(filter, filterIdx) {
              return {
                color: getStandardColor(filterIdx),
                value: filter,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return filter.match(row)
                }),
              }
            }),
          }
        }
        // cols
        {
          ctrl.data.cols = {
            type: 'statement',
            items: statements.map(function(statement, statementIdx) {
              var keys = choices.map(function(choice) {
                if (questionType === Question.Types.CONSTANT_SUM) {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_number`
                } else {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_selected`
                }
              })
              var idxs = keys.map(function(key) {
                return ctrl.datapack.mappings.columns[key]
              })
              return {
                color: getLinearColor(statementIdx, statements.length, true), // why reverse true?
                value: statement,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return _.some(idxs, function(idx) {
                    return ~~row[idx]
                  })
                }),
              }
            }),
          }
        }
        // cells
        for (var filter of filters) {
          var row = []
          for (var statement of statements) {
            var key
            if (questionType === Question.Types.CONSTANT_SUM) {
              key = `q_${questionId}_l_${loop}_s_${statement.id}_c_${choiceId}_number`
            } else {
              key = `q_${questionId}_l_${loop}_s_${statement.id}_c_${choiceId}_selected`
            }
            var idx = ctrl.datapack.mappings.columns[key]
            var cell = makeCell(function(row) {
              var filterMatch = filter.match(row)
              if (!filterMatch) return 0
              return ~~row[idx]
            })
            cell.weight = 0
            row.push(cell)
          }
          matrix.push(row)
        }
        ctrl.data.matrix = matrix
        if (rows === 'statements') {
          flip()
        }
        finalize()
      }

      //
      // (choices x statements)
      //

      if (tuple.includes('choices') && tuple.includes('statements')) {
        var choices = view.configs.choices.items.filter(function(choice) {
          return choice.redact !== 'exclude'
        })
        var statements = view.configs.statements.items.filter(function(
          statement
        ) {
          return statement.redact !== 'exclude'
        })
        var matrix = []
        var linear = hasLinearChoiceColors(questionId)
        ensureMergeCol(
          choices,
          statements.map(function(s) {
            return s.id
          })
        )
        // rows
        {
          ctrl.data.rows = {
            type: 'choice',
            items: choices.map(function(choice, choiceIdx) {
              var keys = statements.map(function(statement) {
                if (questionType === Question.Types.CONSTANT_SUM) {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_number`
                } else {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_selected`
                }
              })
              var idxs = keys.map(function(key) {
                return ctrl.datapack.mappings.columns[key]
              })
              return {
                color: linear ? getLinearColor(choiceIdx, choices.length) : getStandardColor(choiceIdx), // prettier-ignore
                value: choice,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return _.some(idxs, function(idx) {
                    return ~~row[idx]
                  })
                }),
              }
            }),
          }
        }
        // cols
        {
          ctrl.data.cols = {
            type: 'statement',
            items: statements.map(function(statement, statementIdx) {
              var keys = choices.map(function(choice) {
                if (questionType === Question.Types.CONSTANT_SUM) {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_number`
                } else {
                  return `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_selected`
                }
              })
              var idxs = keys.map(function(key) {
                return ctrl.datapack.mappings.columns[key]
              })
              return {
                color: getLinearColor(statementIdx, statements.length, true), // why reverse true?
                value: statement,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return _.some(idxs, function(idx) {
                    return ~~row[idx]
                  })
                }),
              }
            }),
          }
        }
        for (var choice of choices) {
          var row = []
          for (var statement of statements) {
            var key
            if (questionType === Question.Types.CONSTANT_SUM) {
              key = `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_number`
            } else {
              key = `q_${questionId}_l_${loop}_s_${statement.id}_c_${choice.id}_selected`
            }
            var idx = ctrl.datapack.mappings.columns[key]
            var cell = makeCell(function(row) {
              return ~~row[idx]
            })
            cell.weight = choice.weight
            row.push(cell)
          }
          matrix.push(row)
        }
        ctrl.data.matrix = matrix
        processCombinedWeighted(true)
        if (rows === 'statements') {
          flip()
        }
        finalize()
      }

      //
      // (choices x ranks)
      //

      if (tuple.includes('choices') && tuple.includes('ranks')) {
        var choices = view.configs.choices.items.filter(function(choice) {
          return choice.redact !== 'exclude'
        })
        var ranks = view.configs.ranks.items.filter(function(rank) {
          return rank.redact !== 'exclude'
        })
        var matrix = []
        ensureMergeCol(choices)
        // rows
        {
          var linear = hasLinearChoiceColors(questionId)
          ctrl.data.rows = {
            type: 'choice',
            items: choices.map(function(choice, choiceIdx) {
              var key = `q_${questionId}_l_${loop}_c_${choice.id}_selected`
              var rowIdx = ctrl.datapack.mappings.columns[key]
              return {
                color: linear ? getLinearColor(choiceIdx, choices.length) : getStandardColor(choiceIdx), // prettier-ignore
                value: choice,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return ~~row[rowIdx]
                }),
              }
            }),
          }
        }
        // cols
        {
          ctrl.data.cols = {
            type: 'rank',
            items: ranks.map(function(rank, rankIdx) {
              var keys = choices.map(function(choice) {
                return `q_${questionId}_l_${loop}_c_${choice.id}_rank`
              })
              var idxs = keys.map(function(key) {
                return ctrl.datapack.mappings.columns[key]
              })
              return {
                color: getStandardColor(rankIdx),
                value: rank,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return _.some(idxs, function(idx) {
                    return ~~row[idx]
                  })
                }),
              }
            }),
          }
        }
        // cells
        for (var choice of choices) {
          var row = []
          for (var rank of ranks) {
            var key = `q_${questionId}_l_${loop}_c_${choice.id}_rank`
            var idx = ctrl.datapack.mappings.columns[key]
            var cell = makeCell(function(row) {
              return row[idx] === rank.id ? 1 : 0
            })
            cell.weight = choice.weight
            row.push(cell)
          }
          matrix.push(row)
        }
        ctrl.data.matrix = matrix
        processCombinedWeighted(true)
        if (rows === 'ranks') {
          flip()
        }
        finalize()
      }

      //
      // (filters x ranks) + choice
      //

      if (tuple.includes('filters') && tuple.includes('ranks')) {
        var filters = getActiveFilters()
        var ranks = view.configs.ranks.items.filter(function(rank) {
          return rank.redact !== 'exclude'
        })
        var choices = view.configs.choices.items
        var choiceId = view.slots.target
        var matrix = []
        ensureMergeCol(choices)
        // rows
        {
          ctrl.data.rows = {
            type: 'filter',
            items: filters.map(function(filter, filterIdx) {
              return {
                color: getStandardColor(filterIdx),
                value: filter,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return filter.match(row)
                }),
              }
            }),
          }
        }
        // cols
        {
          ctrl.data.cols = {
            type: 'rank',
            items: ranks.map(function(rank, rankIdx) {
              var keys = choices.map(function(choice) {
                return `q_${questionId}_l_${loop}_c_${choice.id}_rank`
              })
              var idxs = keys.map(function(key) {
                return ctrl.datapack.mappings.columns[key]
              })
              return {
                color: getStandardColor(rankIdx),
                value: rank,
                count: 0,
                respondents: collectRespondents(function(row) {
                  return _.some(idxs, function(idx) {
                    return ~~row[idx]
                  })
                }),
              }
            }),
          }
        }
        // cells
        for (var filter of filters) {
          var row = []
          for (var rank of ranks) {
            var key = `q_${questionId}_l_${loop}_c_${choiceId}_rank`
            var idx = ctrl.datapack.mappings.columns[key]
            var cell = makeCell(function(row) {
              return row[idx] === rank.id && filter.match(row) ? 1 : 0
            })
            cell.weight = rank.weight
            row.push(cell)
          }
          matrix.push(row)
        }
        ctrl.data.matrix = matrix
        if (rows === 'ranks') {
          flip()
        }
        finalize()
      }

      console.timeEnd('buildData')
    }

    function buildTable() {
      console.time('buildTable')

      var format = ctrl.view.slots.format
      var dp = 0 // 2

      function getValue(cell) {
        if (format === 'count') {
          return cell.count
        }
        if (format === 'sum') {
          return (cell.sum || 0).toFixed(dp)
        }
        if (format === 'average') {
          return (cell.average || 0).toFixed(dp)
        }
        if (format === 'row-percent') {
          return (cell.rowPercent * 100).toFixed(dp) + '%'
        }
        if (format === 'col-percent') {
          return (cell.colPercent * 100).toFixed(dp) + '%'
        }
        if (format === 'total-percent') {
          return (cell.totalPercent * 100).toFixed(dp) + '%'
        }
      }

      // TODO: remove from everywhere, this is now a slot
      var absolute = ctrl.view.table.absolute

      var data = ctrl.data
      ctrl.table = []

      var header = []
      header.push({ type: 'blank' })

      var colType = data.cols.type
      for (var item of data.cols.items) {
        switch (colType) {
          case 'filter':
            header.push({
              type: 'filter',
              color: item.color,
              filter: item.value,
            })
            break
          case 'choice':
          case 'statement':
          case 'rank':
            header.push({
              type: 'text',
              color: item.color,
              text: item.value.label,
            })
            break
        }
      }
      if (colType === 'filter') {
        header.push({ type: 'filteradd' })
      }
      header.push({ type: 'gap' })
      header.push({ type: 'text', text: 'Total (n)', bg: true })
      ctrl.table.push(header)

      var rowType = data.rows.type
      for (let i = 0; i < data.rows.items.length; i++) {
        var item = data.rows.items[i]
        var row = []
        switch (rowType) {
          case 'filter':
            row.push({
              type: 'filter',
              // color: item.color,
              filter: item.value,
            })
            break
          case 'choice':
          case 'statement':
          case 'rank':
            row.push({
              type: 'text',
              // color: item.color,
              text: item.value.label,
            })
            break
        }
        for (var cell of data.matrix[i] || []) {
          row.push({
            type: 'value',
            text: getValue(cell),
          })
        }
        if (colType === 'filter') {
          row.push({ type: 'filterblank' }) // + column
        }
        row.push({ type: 'gap' })
        row.push({
          type: 'value',
          // text: `${item.count} (${((item.count / data.count) * 100).toFixed(0)}%)`, // prettier-ignore
          text: item.count,
          bg: true,
        })
        ctrl.table.push(row)
      }
      if (rowType === 'filter') {
        var row = [{ type: 'filteradd' }]
        for (var item of data.cols.items) {
          row.push({ type: 'filterblank' })
        }
        row.push({ type: 'gap' })
        row.push({ type: 'blank', bg: true })
        ctrl.table.push(row)
      }
      var footer = []
      footer.push({ type: 'text', text: 'Total (n)', bg: true })
      for (var item of data.cols.items) {
        footer.push({
          type: 'value',
          // text: `${item.count} (${((item.count / data.count) * 100).toFixed(0)}%)`, // prettier-ignore
          text: item.count,
          bg: true,
        })
      }
      if (colType === 'filter') {
        footer.push({ type: 'filterblank', bg: true }) // + column
      }
      footer.push({ type: 'gap', bg: true })
      footer.push({
        type: 'value',
        text: ctrl.data.count,
        bg: true,
        bold: true,
      })
      // if (rowType === 'filter') {
      //   footer.push({ type: 'blank' })
      // }
      ctrl.table.push(footer)

      console.timeEnd('buildTable')
    }

    function onCanvasInit() {
      if (ctrl.buildChartPending) {
        ctrl.buildChartPending = false
        buildChart()
      }
    }

    function buildChart() {
      $timeout(function() {
        var container = document.getElementsByClassName('survey-explorer__chart-main')[0] // prettier-ignore
        var elem = document.getElementById('chart')
        if (!elem) {
          ctrl.buildChartPending = true
          return
        }
        var ctx = elem.getContext('2d')
        var data = ctrl.data

        var format = ctrl.view.slots.format
        var stacked = ctrl.view.chart.stacked
        var rotate = ctrl.view.chart.rotate

        // TODO: remove, now using format
        // var absolute = ctrl.view.chart.absolute
        var absolute =
          format === 'count' || format === 'sum' || format === 'average'

        function getValue(cell) {
          if (format === 'count') {
            return cell.count
          }
          if (format === 'sum') {
            return cell.sum
          }
          if (format === 'average') {
            return cell.average
          }
          if (format === 'row-percent') {
            return cell.rowPercent * 100
          }
          if (format === 'col-percent') {
            return cell.colPercent * 100
          }
          if (format === 'total-percent') {
            return cell.totalPercent * 100
          }
        }

        // labels
        var labels = []

        // datasets
        var datasets = []

        for (var item of data.rows.items) {
          switch (data.rows.type) {
            case 'filter':
              labels.push(item.value.name)
              break
            case 'choice':
            case 'statement':
            case 'rank':
              labels.push(item.value.label)
              break
          }
        }
        for (let i = 0; i < data.cols.items.length; i++) {
          var item = data.cols.items[i]
          var dataset = {
            label: null,
            data: [],
            backgroundColor: [],
          }
          switch (data.cols.type) {
            case 'filter':
              dataset.label = item.value.name
              dataset.backgroundColor = item.color
              break
            case 'choice':
            case 'statement':
            case 'rank':
              dataset.label = item.value.label
              dataset.backgroundColor = item.color
              break
          }
          for (let k = 0; k < data.matrix.length; k++) {
            var cell = data.matrix[k][i]
            dataset.data.push(getValue(cell))
            // dataset.data.push(absolute ? cell.count : cell.ratio * 100)
            // dataset.backgroundColor.push(data.rows.items[k].color)
          }
          datasets.push(dataset)
        }

        var x = {
          stacked: stacked,
          grid: {
            display: false,
          },
          ticks: {
            callback(value) {
              var label = this.getLabelForValue(value)
              return _.truncate(label, { length: 22 })
            },
          },
        }

        var y = {
          beginAtZero: true,
          min: absolute ? undefined : 0,
          max: undefined,
          stacked: stacked,
          grid: {
            display: true,
          },
          ticks: {
            precision: 0,
            callback: function(value) {
              if (absolute) {
                return value
              }
              return value + '%'
            },
          },
        }

        if (rotate) {
          var z = x
          x = y
          y = z
        }

        // var barPercentage = stacked ? 0.7 : 0.9
        // var categoryPercentage = 1
        var barPercentage = 0.9
        var categoryPercentage = 0.9

        var config = {
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets,
          },
          plugins: [window.ChartDataLabels],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            borderRadius: 4,
            barPercentage: barPercentage,
            categoryPercentage: categoryPercentage,
            // maxBarThickness: 60,
            indexAxis: rotate ? 'y' : 'x',
            scales: {
              x: x,
              y: y,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                position: 'average',
                callbacks: {
                  // title: function(ctx) {
                  //   var datasetIndex = ctx[0].datasetIndex
                  //   var dataIndex = ctx[0].dataIndex
                  //   return sets[datasetIndex].aspects[dataIndex].label
                  // },
                  label: function(ctx) {
                    var value = rotate ? ctx.parsed.x : ctx.parsed.y
                    var dp = 0 // 2
                    value = _.round(value, dp)
                    var prefix = ''
                    var suffix = ''
                    prefix = ctx.dataset.label + ': '
                    if (absolute) {
                      // var percent = data.matrix[ctx.dataIndex][ctx.datasetIndex].ratio * 100 // prettier-ignore
                      // suffix += ` (${percent}%)`
                    } else {
                      suffix += '%'
                      // var total = data.matrix[ctx.dataIndex][ctx.datasetIndex].count // prettier-ignore
                      // suffix += ` (${total})`
                    }
                    return prefix + value + suffix
                  },
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'start',
                offset: 10,
                color: 'white',
                font: {
                  family: 'Roboto, sans-serif',
                  weight: '500',
                  lineHeight: 0,
                },
                clip: true,
                formatter: function(value, ctx) {
                  if (!value) {
                    return ''
                  }
                  if (absolute) {
                    return _.round(value, 0)
                  }
                  return _.round(value, 0) + '%'
                },
                display: function(ctx) {
                  var chartSize = rotate ? ctx.chart.height : ctx.chart.width
                  chartSize -= rotate ? 60 : 60 // subtract gutter/axis size
                  var numLabels = stacked ? data.matrix.length : data.matrix.length * data.matrix[0].length // prettier-ignore
                  var labelSize = rotate ? 20 : 50
                  var totalSize = labelSize * numLabels
                  // console.log('chartSize', chartSize)
                  // console.log('numLabels', numLabels)
                  // console.log('totalSize', totalSize) // prettier-ignore
                  if (totalSize > chartSize) return false
                  return true
                },
              },
            },
          },
        }

        // var idealBarSize = rotate ? 34 : 80

        // var size = config.data.datasets.length * config.data.labels.length
        var idealBarSize = rotate ? 34 : 80
        var barsPerCategory = stacked ? 1 : config.data.datasets.length
        var numCategories = config.data.labels.length
        var categorySize = (idealBarSize * barsPerCategory) / barPercentage
        var sampleSize = (categorySize * numCategories) / categoryPercentage
        var axisSize = rotate ? 34 : 45 // estimated gutter?
        if (rotate) {
          // define the chart height to match the target bar size
          container.style.flexBasis = axisSize + sampleSize + 'px'
          container.style.maxWidth = '9999px'
        } else {
          container.style.maxWidth = axisSize + sampleSize + 'px'
          container.style.flexBasis = '400px'
        }

        console.log({ stacked, rotate })

        console.log('chart', _.cloneDeep(config))

        if (ctrl.chart) {
          ctrl.chart.destroy()
        }
        ctrl.chart = new Chart(ctx, config)

        // container.style.maxWidth = '100px'

        // ctrl.chartRebuild = true

        // if (ctrl.chartRebuild) {
        //   if (ctrl.chart) {
        //     ctrl.chart.destroy()
        //     ctrl.chart = null
        //   }
        //   ctrl.chartRebuild = false
        // }

        // if (!ctrl.chart) {
        //   ctrl.chart = new Chart(ctx, config)
        // } else {
        //   var chart = ctrl.chart
        //   chart.data.labels = config.data.labels
        //   _.each(config.data.datasets, function(dataset, idx) {
        //     if (!chart.data.datasets[idx]) {
        //       chart.data.datasets[idx] = {}
        //     }
        //     chart.data.datasets[idx].label = dataset.label
        //     chart.data.datasets[idx].data = dataset.data
        //     chart.data.datasets[idx].backgroundColor = dataset.backgroundColor // prettier-ignore
        //   })
        //   chart.options.indexAxis = config.options.indexAxis
        //   _.each(['x', 'y'], function(axis) {
        //     var op = axis === 'y' ? y : x
        //     var scale = chart.options.scales[axis]
        //     scale.stacked = op.stacked
        //     scale.grid.display = op.grid.display
        //     scale.ticks.callback = op.ticks.callback
        //   })

        //   chart.update()
        //   // chart.resize()
        // }
      }, 10)
    }

    function buildCloud() {
      ctrl.cloud = {
        sentences: ctrl.list.items.map(function(item) {
          return item.text
        }),
      }
    }

    function isSlot(slot) {
      if (slot === 'loop') {
        return ctrl.view.loops.length
      }
      if (slot === 'target') {
        return ctrl.slotOptions.target.length
      }
      if (slot === 'rows') return true
      if (slot === 'columns') return true
      if (slot === 'choice') {
        return (
          ctrl.view.slots.rows !== 'choices' &&
          ctrl.view.slots.columns !== 'choices'
        )
      }
      if (slot === 'statement') {
        return (
          ctrl.view.statements.length &&
          ctrl.view.slots.rows !== 'statements' &&
          ctrl.view.slots.columns !== 'statements'
        )
      }
      if (slot === 'rank') {
        return (
          ctrl.view.ranks.length &&
          ctrl.view.slots.rows !== 'ranks' &&
          ctrl.view.slots.columns !== 'ranks'
        )
      }
      if (slot === 'format') {
        return true
      }
    }

    function getSlotOptions(slot) {
      if (slot === 'loop') return ctrl.slotOptions.loop
      if (slot === 'target') return ctrl.slotOptions.target
      if (slot === 'rows') return ctrl.slotOptions.rows
      if (slot === 'columns') return ctrl.slotOptions.columns
      if (slot === 'format') return ctrl.slotOptions.format
    }

    function getSlotValue(slot) {
      if (slot === 'loop') return ctrl.view.slots.loop
      if (slot === 'target') return ctrl.view.slots.target
      if (slot === 'rows') return ctrl.view.slots.rows
      if (slot === 'columns') return ctrl.view.slots.columns
      if (slot === 'format') return ctrl.view.slots.format
    }

    function setSlotValue(slot, id) {
      var view = ctrl.view
      if (slot === 'loop') {
        view.slots.loop = id
      }
      if (slot === 'target') {
        view.slots.target = id
        const value = ctrl.slotOptions.target.find(function(item) {
          return item.id === id
        })
        // if we did pick a target (not all)
        if (id) {
          var target = value.type
          var rows = view.slots.rows
          var cols = view.slots.columns
          var isRank = view.ranks.length
          var rankOrStatement = isRank ? 'ranks' : 'statements'
          if (target === 'choice') {
            if (rows === 'choices' && cols === 'filters') rows = rankOrStatement
            if (cols === 'choices' && rows === 'filters') cols = rankOrStatement
            if (rows === 'choices' && cols === rankOrStatement) rows = 'filters'
            if (cols === 'choices' && rows === rankOrStatement) cols = 'filters'
          }
          if (target === 'statement') {
            if (rows === 'statements' && cols === 'filters') rows = 'choices'
            if (cols === 'statements' && rows === 'filters') cols = 'choices'
            if (rows === 'statements' && cols === 'choices') rows = 'filters'
            if (cols === 'statements' && rows === 'choices') cols = 'filters'
          }
          if (target === 'rank') {
            if (rows === 'ranks' && cols === 'filters') rows = 'choices'
            if (cols === 'ranks' && rows === 'filters') cols = 'choices'
            if (rows === 'ranks' && cols === 'choices') rows = 'filters'
            if (cols === 'ranks' && rows === 'choices') cols = 'filters'
          }
          view.slots.rows = rows
          view.slots.columns = cols
        }
        // if we picked no target (all)
        if (!id) {
          // we need to ensure filters isn't selected as a row/column
          if (view.ranks.length) {
            if (view.slots.rows === 'filters') {
              view.slots.rows =
                view.slots.columns === 'ranks' ? 'choices' : 'ranks'
            }
            if (view.slots.columns === 'filters') {
              view.slots.columns =
                view.slots.rows === 'ranks' ? 'choices' : 'ranks'
            }
          } else {
            if (view.slots.rows === 'filters') {
              view.slots.rows =
                view.slots.columns === 'statements' ? 'choices' : 'statements'
            }
            if (view.slots.columns === 'filters') {
              view.slots.columns =
                view.slots.rows === 'statements' ? 'choices' : 'statements'
            }
          }
        }
      }
      if (slot === 'rows') {
        var rows = view.slots.rows
        var cols = view.slots.columns
        var target = view.slots.target
        var prevRows = view.slots.rows
        var isRank = view.ranks.length
        // set it
        rows = id
        // if we set it to the same as columns then this is a switch
        if (cols === rows) {
          cols = prevRows
        }
        // if there are no filters in rows/cols then we need to clear the target
        if (rows !== 'filters' && cols !== 'filters') {
          target = null
        }
        // if there are filters in rows/cols, ensure the target matches the correct type
        if (rows === 'filters' || cols === 'filters') {
          // resolve what the target type should be
          var otherSlot = cols === 'filters' ? rows : cols
          var requiredType
          if (otherSlot === 'choices') requiredType = isRank ? 'rank' : 'statement' // prettier-ignore
          if (otherSlot === 'statements') requiredType = 'choice'
          if (otherSlot === 'ranks') requiredType = 'choice'
          // clear target if its not the required type
          if (target) {
            var opt = ctrl.slotOptions.target.find(function(opt) {
              return opt.id === target
            })
            if (opt.type !== requiredType) target = null
          }
          // if there is no target set, pick one of the required type
          if (!target) {
            for (const option of ctrl.slotOptions.target) {
              if (option.type === requiredType) {
                target = option.id
                break
              }
            }
          }
        }
        view.slots.rows = rows
        view.slots.columns = cols
        view.slots.target = target
      }
      if (slot === 'columns') {
        var rows = view.slots.rows
        var cols = view.slots.columns
        var target = view.slots.target
        var prevCols = view.slots.columns
        var isRank = view.ranks.length
        // set it
        cols = id
        // if we set it to the same as columns then this is a switch
        if (cols === rows) {
          rows = prevCols
        }
        // if there are no filters in rows/cols then we need to clear the target
        if (rows !== 'filters' && cols !== 'filters') {
          target = null
        }
        // if there are filters in rows/cols, ensure the target matches the correct type
        if (rows === 'filters' || cols === 'filters') {
          // resolve what the target type should be
          var otherSlot = cols === 'filters' ? rows : cols
          var requiredType
          if (otherSlot === 'choices') requiredType = isRank ? 'rank' : 'statement' // prettier-ignore
          if (otherSlot === 'statements') requiredType = 'choice'
          if (otherSlot === 'ranks') requiredType = 'choice'
          // clear target if its not the required type
          if (target) {
            var opt = ctrl.slotOptions.target.find(function(opt) {
              return opt.id === target
            })
            if (opt.type !== requiredType) target = null
          }
          // if there is no target set, pick one of the required type
          if (!target) {
            for (const option of ctrl.slotOptions.target) {
              if (option.type === requiredType) {
                target = option.id
                break
              }
            }
          }
        }
        view.slots.rows = rows
        view.slots.columns = cols
        view.slots.target = target
      }
      if (slot === 'format') {
        view.slots.format = id
      }
      build()
    }

    function isSlotConfig(slot) {
      var view = ctrl.view
      if (slot === 'loop') {
        return false
      }
      if (slot === 'target') {
        return false
      }
      if (slot === 'rows') {
        const id = view.slots.rows
        return (
          ['choices'].includes(id) &&
          [
            Question.Types.CHOICE,
            Question.Types.MATRIX,
            Question.Types.SCALE,
            Question.Types.NPS,
            Question.Types.MOOD,
            Question.Types.RATING,
            Question.Types.SCORE,
          ].includes(view.questionType)
        )
      }
      if (slot === 'columns') {
        const id = view.slots.columns
        return (
          ['choices'].includes(id) &&
          [
            Question.Types.CHOICE,
            Question.Types.MATRIX,
            Question.Types.SCALE,
            Question.Types.NPS,
            Question.Types.MOOD,
            Question.Types.RATING,
            Question.Types.SCORE,
          ].includes(view.questionType)
        )
      }
      if (slot === 'choice') {
        return false
      }
      if (slot === 'statement') {
        return false
      }
      if (slot === 'rank') {
        return false
      }
      if (slot === 'format') {
        return false
      }
    }

    function editSlotConfig(slot) {
      var view = ctrl.view
      if (slot === 'loop') {
        // ...
      }
      if (slot === 'rows') {
        const id = view.slots.rows
        if (id === 'choices') {
          showModsDialog(view.configs.choices, view.choices).then(function(
            config
          ) {
            view.configs.choices = config
            build()
          })
        }
        if (id === 'statements') {
          // ...
        }
        if (id === 'filters') {
          // N/A
        }
      }
      if (slot === 'columns') {
        const id = view.slots.columns
        if (id === 'choices') {
          showModsDialog(view.configs.choices, view.choices).then(function(
            config
          ) {
            view.configs.choices = config
            build()
          })
        }
        if (id === 'statements') {
          // ...
        }
        if (id === 'filters') {
          // N/A
        }
      }
      if (slot === 'choice') {
        // ...
      }
      if (slot === 'statement') {
        // ...
      }
      if (slot === 'rank') {
        // ...
      }
      if (slot === 'format') {
        // ...
      }
    }

    function showFilterDialog(filter) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-filter-dialog__dialog">',
          '<survey-explorer-filter-dialog ',
            'channels="channels"',
            'datapack="datapack"',
            'dashboard="dashboard"',
            'filter="filter"',
            'on-done="dialog.close($filter)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          channels: ctrl.channels,
          datapack: ctrl.datapack,
          dashboard: ctrl.dashboard,
          filter: filter,
        },
      }
      return glDialog.show(options)
    }

    function getAllPrefilters() {
      var global = ctrl.dashboard.prefilters.map(id => ctrl.filtersById[id])
      var local = ctrl.view.prefilters.map(id => ctrl.filtersById[id])
      return global.concat(local)
    }

    function isGlobalPrefilter(filter) {
      return ctrl.dashboard.prefilters.includes(filter.id)
    }

    function isLocalPrefilter(filter) {
      return ctrl.view.prefilters.includes(filter.id)
    }

    function createFilter() {
      return showFilterDialog(null).then(function(filter) {
        ctrl.dashboard.filters.push(filter)
        ctrl.filtersById[filter.id] = filter
        filter.match = filter.toDatapackMatcher(ctrl.datapack)
        return filter
      })
    }

    function editFilter(event, filter) {
      if (event && event.defaultPrevented) return
      if (ctrl.isShared && filter.restricted) return
      toggleMenu(null)
      showFilterDialog(filter).then(function(nextFilter) {
        filter.deserialize(nextFilter.serialize())
        filter.match = filter.toDatapackMatcher(ctrl.datapack)
        build()
      })
    }

    function moveFilter(filter, offset) {
      var filters = ctrl.dashboard.filters
      const idx = filters.indexOf(filter)
      if (idx === -1) return
      let newIdx = idx + offset
      newIdx = Math.max(0, Math.min(filters.length - 1, newIdx))
      filters.splice(idx, 1)
      if (newIdx >= filters.length) {
        filters.push(filter)
      } else {
        // Insert the item at its new position
        filters.splice(newIdx, 0, filter)
      }
    }

    function duplicateFilter(filter) {
      if (ctrl.isShared && filter.restricted) return
      toggleMenu(null)
      var idx = ctrl.dashboard.filters.indexOf(filter)
      var newFilter = filter.duplicate()
      ctrl.dashboard.filters.splice(idx + 1, 0, newFilter)
      ctrl.filtersById[newFilter.id] = newFilter
      newFilter.match = newFilter.toDatapackMatcher(ctrl.datapack)
    }

    function togglePrivateFilter(filter) {
      filter.private = !filter.private
    }

    function deleteFilter(filter) {
      // remove from prefilters
      removePrefilter(null, filter)
      // remove from view configs
      for (var view of ctrl.dashboard.views) {
        removeViewFilter(view, filter)
      }

      var filters = ctrl.dashboard.filters
      const idx = filters.indexOf(filter)
      filters.splice(idx, 1)
      delete ctrl.filtersById[filter.id]
      build()
    }

    function removePrefilter(event, filter) {
      if (event) event.preventDefault()
      var idx = ctrl.dashboard.prefilters.indexOf(filter.id)
      if (idx !== -1) {
        ctrl.dashboard.prefilters.splice(idx, 1)
      }
      idx = ctrl.view.prefilters.indexOf(filter.id)
      if (idx !== -1) {
        ctrl.view.prefilters.splice(idx, 1)
      }
      build()
    }

    function showFiltersDialog(opts) {
      var title = opts.title
      var canAddGlobal = opts.canAddGlobal
      var hiddenIds = opts.hiddenIds
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-filters-dialog__dialog">',
          '<survey-explorer-filters-dialog ',
            'title="title"',
            'dashboard="dashboard"',
            'view="view"',
            'can-add-global="canAddGlobal"',
            'hidden-ids="hiddenIds"',
            'is-shared="isShared"',
            'create-filter="createFilter()"',
            'on-done="dialog.close($result)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          title: title,
          dashboard: ctrl.dashboard,
          view: ctrl.view,
          canAddGlobal: canAddGlobal,
          hiddenIds: hiddenIds,
          isShared: ctrl.isShared,
          createFilter: createFilter,
        },
      }
      return glDialog.show(options)
    }

    function addPrefilters() {
      showFiltersDialog({ title: 'Add Prefilters', canAddGlobal: true }).then(
        function(result) {
          var filters = result.filters
          var global = result.global
          function remove(filterId, global) {
            var arr = global ? ctrl.dashboard.prefilters : ctrl.view.prefilters
            var idx = arr.indexOf(filterId)
            if (idx !== -1) {
              arr.splice(idx, 1)
            }
          }
          function add(filterId, global) {
            var arr = global ? ctrl.dashboard.prefilters : ctrl.view.prefilters
            var idx = arr.indexOf(filterId)
            if (idx === -1) {
              arr.push(filterId)
            }
          }
          for (var filter of filters) {
            remove(filter.id, !global)
            add(filter.id, global)
          }
          build()
        }
      )
    }

    function addViewFilters() {
      showFiltersDialog({
        title: 'Add Filters',
        canAddGlobal: false,
        hiddenIds: ctrl.view.configs.filters.items,
      }).then(function(result) {
        var filters = result.filters
        for (var filter of filters) {
          var arr = ctrl.view.configs.filters.items
          var idx = arr.indexOf(filter.id)
          if (idx === -1) {
            arr.push(filter.id)
          }
        }
        build()
      })
    }

    function removeViewFilter(view, filter) {
      var idx = view.configs.filters.items.indexOf(filter.id)
      console.log(idx)
      if (idx !== -1) {
        view.configs.filters.items.splice(idx, 1)
      }
      build()
    }

    function hasTable(view) {
      return [
        Question.Types.CHOICE,
        Question.Types.RANK,
        Question.Types.SCALE,
        Question.Types.NPS,
        Question.Types.MOOD,
        Question.Types.RATING,
        Question.Types.MATRIX,
        Question.Types.NUMERIC, // TOOD: verify
        Question.Types.HIDDEN_VARIABLES, // TODO: verify
        Question.Types.CONSTANT_SUM, // TODO: support
        Question.Types.SCORE, // TODO: support
      ].includes(ctrl.question.type)
    }

    function hasChart(view) {
      return [
        Question.Types.CHOICE,
        Question.Types.RANK,
        Question.Types.SCALE,
        Question.Types.NPS,
        Question.Types.MOOD,
        Question.Types.RATING,
        Question.Types.NUMERIC, // TOOD: verify
        Question.Types.MATRIX,
        Question.Types.CONSTANT_SUM, // TODO: support
        Question.Types.SCORE, // TODO: support
        Question.Types.HIDDEN_VARIABLES, // TODO: verify
      ].includes(ctrl.question.type)
    }

    function hasList(view) {
      return [
        Question.Types.SINGLE_TEXT,
        Question.Types.TEXT,
        Question.Types.SCAN,
        Question.Types.FOLLOW_UP, // TODO: verify
      ].includes(ctrl.question.type)
    }

    function hasCloud(view) {
      return [
        Question.Types.SINGLE_TEXT,
        Question.Types.TEXT,
        Question.Types.SCAN,
      ].includes(ctrl.question.type)
    }

    function hasImages(view) {
      return [Question.Types.IMAGE].includes(ctrl.question.type)
    }

    function hasMap(view) {
      return [Question.Types.LOCATION].includes(ctrl.question.type)
      // DEPRECATED: Question.Types.PLACES_NEAR_ME
    }

    function buildList() {
      var view = ctrl.view
      var query = ctrl.view.list.query.toLowerCase()
      var datapack = ctrl.datapack
      var dashboard = ctrl.dashboard
      var prefilters = getAllPrefilters()
      var csv = ctrl.csv.filter(function(row) {
        return _.every(prefilters, function(filter) {
          return filter.match(row)
        })
      })
      // the completesOnly setting (default) can be switched off to include exits & overquotas too
      if (dashboard.completesOnly) {
        csv = csv.filter(function(row) {
          return ctrl.completesOnlyFilter.match(row)
        })
      }
      var questionId = view.questionId
      var loop = view.slots.loop || '-'
      var textKey = `q_${questionId}_l_${loop}_text`
      var textIdx = datapack.mappings.columns[textKey]
      var idIdx = datapack.mappings.columns.id
      ctrl.list = {}
      ctrl.list.items = csv
        .filter(function(row) {
          var value = row[textIdx]
          return value && value.toLowerCase().includes(query)
        })
        .map(function(row) {
          return {
            id: row[idIdx],
            text: row[textIdx],
          }
        })
      ctrl.list.count = ctrl.list.items.length
    }

    function queryList(query) {
      ctrl.view.list.query = query
      $scope.$applyAsync(function() {
        buildList()
        buildCloud()
      })
    }

    function buildImages() {
      console.log('buildImages')
      var view = ctrl.view
      var datapack = ctrl.datapack
      var prefilters = getAllPrefilters()
      var csv = ctrl.csv.filter(function(row) {
        return _.every(prefilters, function(filter) {
          return filter.match(row)
        })
      })
      var questionId = view.questionId
      var loop = view.slots.loop || '-'
      var textKey = `q_${questionId}_l_${loop}_text`
      var textIdx = datapack.mappings.columns[textKey]
      var idIdx = datapack.mappings.columns.id
      ctrl.images = {}
      ctrl.images.items = csv
        .filter(function(row) {
          var value = row[textIdx]
          return value
        })
        .map(function(row) {
          return {
            id: row[idIdx],
            url: row[textIdx],
          }
        })
      ctrl.images.count = ctrl.images.items.length
    }

    function buildMap() {
      var view = ctrl.view
      var datapack = ctrl.datapack
      var dashboard = ctrl.dashboard
      var prefilters = getAllPrefilters()
      var csv = ctrl.csv.filter(function(row) {
        return _.every(prefilters, function(filter) {
          return filter.match(row)
        })
      })
      // the completesOnly setting (default) can be switched off to include exits & overquotas too
      if (dashboard.completesOnly) {
        csv = csv.filter(function(row) {
          return ctrl.completesOnlyFilter.match(row)
        })
      }
      var questionId = view.questionId
      var loop = view.slots.loop || '-'
      var textKey = `q_${questionId}_l_${loop}_text`
      var textIdx = datapack.mappings.columns[textKey]
      var idIdx = datapack.mappings.columns.id
      var textAnswers = csv
        .filter(function(row) {
          var value = row[textIdx]
          return value
        })
        .map(function(row) {
          return {
            responseId: row[idIdx],
            textAnswer: row[textIdx],
          }
        })
      ctrl.map = {}
      ctrl.map.locationSet = new LocationSet().fromTextAnswers(textAnswers)
      ctrl.map.count = textAnswers.length
    }

    function showThemeDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-theme-dialog__dialog">',
          '<survey-explorer-theme-dialog ',
            'dashboard="dashboard"',
            'view="view"',
            'on-done="dialog.close()" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          dashboard: ctrl.dashboard,
          view: ctrl.view,
        },
      }
      return glDialog.show(options)
    }

    function editTheme() {
      showThemeDialog().finally(function() {
        // TODO: support cancel
        build()
      })
    }

    function exportAs(type) {
      if (type === 'workbook') {
        return exportDashboardWorkbook()
      }
      var survey = ctrl.datapack.survey
      var responseType = null // ctrl.getSelectedResponseType()
      if (!survey.questions) {
        survey.questions = survey.views
          .filter(function(view) {
            return view.type === 'QUESTION'
          })
          .map(function(view) {
            return view.value
          })
      }
      if (['qcsv', 'qxlsx', 'qspss'].includes(type)) {
        // this export is temporary and should not be available
        // with the new analysis since it focuses on outputting the visualisations
        var questionIds = []
        if (ctrl.options.visibilityMode !== ReportLink.Modes.ALL) {
          questionIds = ctrl.dashboard.views.map(function(view) {
            return view.questionId
          })
          // questionIds = survey.questions
          //   .filter(function(question) {
          //     return reportLinkService.isViewVisible(
          //       question.id,
          //       ctrl.options.visibilityMode,
          //       ctrl.options.visibilityViewIds
          //     )
          //   })
          //   .map(function(question) {
          //     return question.id
          //   })
        }
        var options = {
          surveyId: survey.id,
          responseType: responseType,
          shareToken: null, // ctrl.options.shareToken,
          questionIds: questionIds,
          labelsToRewrite: {}, // survey.labelsToRewrite,
          onlyLoopVariableIds: null, // ctrl.options.onlyLoopVariableIds,
          filterSets: null, // ctrl.filterSetGroup.getSelected(),
          filterSetsOperator: null, // ctrl.filterSetGroup.getOperator(),
        }
        if (type === 'qxlsx') {
          options.exportFormat = 'xlsx'
        }
        if (type === 'qspss') {
          options.exportFormat = 'spss'
        }
        // IMPORTANT: SPSS doesn't support durations so we should always set it to false
        options.withDuration =
          options.exportFormat !== 'spss' &&
          ctrl.isSSR &&
          ctrl.subscriber.data.withDurationExport
        surveyReport.createExport(options)
      }
      if (type === 'xlsx') {
        reportQuestionExporter.exportDashboardWorkbook(
          survey,
          null, // ctrl.filterSetGroup.getSelected(),
          null, // ctrl.filterSetGroup.getOperator(),
          responseType
        )
      }
      if (type === 'pptx') {
        reportPPTXExporter.export(survey)
      }
    }

    // TODO: there are others
    var linearChoiceQuestionTypes = [
      Question.Types.MATRIX,
      Question.Types.SCALE,
    ]
    function hasLinearChoiceColors(questionId) {
      var question = questionsById[questionId]
      if (linearChoiceQuestionTypes.includes(question.type)) {
        return true
      }
    }

    function getStandardColor(num, reverse) {
      console.log('getStandardColor', num, reverse)
      var set = reverse
        ? ctrl.theme.standard.slice().reverse()
        : ctrl.theme.standard
      return set[num % set.length]
    }

    function getLinearColor(num, steps, reverse) {
      var set = reverse
        ? ctrl.theme.linear.slice().reverse()
        : ctrl.theme.linear
      set = colors.getGradient(set, steps)
      return set[num % set.length]
    }

    function toggleChartStacked(value) {
      value = _.isBoolean(value) ? value : !ctrl.view.chart.stacked
      ctrl.view.chart.stacked = value
      buildChart()
    }

    function toggleChartRotate(value) {
      value = _.isBoolean(value) ? value : !ctrl.view.chart.rotate
      ctrl.view.chart.rotate = value
      buildChart()
    }

    function toggleChartAbsolute(value) {
      value = _.isBoolean(value) ? value : !ctrl.view.chart.absolute
      ctrl.view.chart.absolute = value
      buildChart()
    }

    function toggleTableAbsolute(value) {
      value = _.isBoolean(value) ? value : !ctrl.view.table.absolute
      ctrl.view.table.absolute = value
      buildTable()
    }

    function showModsDialog(config, options) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-mods-dialog__dialog">',
          '<survey-explorer-mods-dialog ',
            'config="config"',
            'options="options"',
            'on-done="dialog.close($config)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          config: config,
          options: options,
        },
      }
      return glDialog.show(options)
    }

    function showSettingsDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-explorer-settings-dialog__dialog">',
          '<survey-explorer-settings-dialog ',
            'dashboard="dashboard"',
            'on-apply="dialog.close($remove)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var dashboard = ctrl.dashboard.clone()
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          dashboard: dashboard,
        },
      }
      return glDialog.show(options).then(function(remove) {
        if (remove) {
          destroyDashboard()
        } else {
          // console.log(ctrl.dashboard.serialize())
          ctrl.dashboard.copy(dashboard)
          syncDashboard(ctrl.dashboard)
          // console.log(ctrl.dashboard.serialize())
          build()
        }
      })
    }

    function editSettings() {
      showSettingsDialog()
    }

    function exitBeta() {
      $rootScope.$emit('toggleBeta', false)
    }

    function copyShareLink() {
      const url = configService.getSubscriberPortalUrl(
        '/dashboards/' + ctrl.dashboard.id
      )
      clipboardService.copy(url)
    }

    function save() {
      if (ctrl.dashboard.saving) return
      updateRestricted()
      var oldId = ctrl.dashboard.isNew ? ctrl.dashboard.id : null
      datapackService.saveDashboard(ctrl.dashboard).then(function() {
        syncDashboard(ctrl.dashboard, oldId)
        watchUnsaved(true)
      })
    }

    function exportDashboardWorkbook() {
      var currentView = ctrl.view
      // convert views to sheets
      var sheets = []
      for (var view of ctrl.dashboard.views) {
        setView(null, view)
        addWorkbookSheetFromCurrentView(sheets)
      }
      setView(null, currentView)
      surveyExplorerXLSXService.generate(sheets).then(function(resp) {
        const blob = new Blob([resp], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'workbook.xlsx'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      })
    }

    function addWorkbookSheetFromCurrentView(sheets) {
      var view = ctrl.view
      var sheet = {
        name: '' + (sheets.length + 1), // view.label
        rows: [],
      }
      // list
      if (hasList(ctrl.view)) {
        sheet.rows.push(['Prefilters', getPrefilterNames().join(', ') || '-']) // prettier-ignore
        sheet.rows.push([view.label])
        for (var item of ctrl.list.items) {
          sheet.rows.push([item.text])
        }
        sheets.push(sheet)
      }
      // map
      if (hasMap(ctrl.view)) {
        sheet.rows.push(['Prefilters', getPrefilterNames().join(', ') || '-']) // prettier-ignore
        sheet.rows.push([view.label])
        sheet.rows.push([
          'Country',
          'State',
          'City',
          'Suburb',
          'Postcode',
          'LatLng',
          'Name',
        ])
        for (var item of ctrl.map.locationSet.locations) {
          sheet.rows.push([
            item.country,
            item.state,
            item.city,
            item.suburb,
            item.postcode,
            item.latlng,
            item.name,
          ])
        }
        sheets.push(sheet)
      }
      // table
      if (hasTable(ctrl.view)) {
        sheet.rows.push(['Prefilters', getPrefilterNames().join(', ') || '-']) // prettier-ignore
        if (ctrl.view.slots.loop) {
          sheet.rows.push(['Loop', getSlotLabel('loop', ctrl.view.slots.loop)]) // prettier-ignore
        }
        if (ctrl.view.slots.target) {
          sheet.rows.push(['Target', getSlotLabel('target', ctrl.view.slots.target)]) // prettier-ignore
        }
        sheet.rows.push(['Rows', getSlotLabel('rows', ctrl.view.slots.rows)])
        sheet.rows.push(['Columns', getSlotLabel('columns', ctrl.view.slots.columns)]) // prettier-ignore
        sheet.rows.push(['Values', getSlotLabel('format', ctrl.view.slots.format)]) // prettier-ignore
        for (let i = 0; i < ctrl.table.length; i++) {
          var tableRow = ctrl.table[i]
          // skip entire row if its a filter add
          if (tableRow[0].type === 'filteradd') continue
          // otherwise generate row
          var row = []
          for (var cell of tableRow) {
            if (cell.type === 'blank') {
              row.push(i === 0 && !row.length ? view.label : '')
            } else if (cell.type === 'filter') {
              row.push(cell.filter.name)
            } else if (cell.type === 'filteradd') {
              // n/a
            } else if (cell.type === 'filterblank') {
              // n/a
            } else if (cell.type === 'gap') {
              // n/a
            } else if (cell.type === 'text') {
              row.push(cell.text)
            } else if (cell.type === 'value') {
              row.push(cell.text)
            }
          }
          sheet.rows.push(row)
        }
        sheets.push(sheet)
      }
    }

    function getPrefilterNames() {
      var names = []
      // global prefilters
      for (var filterId of ctrl.dashboard.prefilters) {
        var filter = ctrl.filtersById[filterId]
        names.push(filter.name)
      }
      // local prefilters
      for (var filterId of ctrl.view.prefilters) {
        var filter = ctrl.filtersById[filterId]
        names.push(filter.name)
      }
      return names
    }

    function getSlotLabel(type, id) {
      var option = ctrl.slotOptions[type].find(function(option) {
        return option.id === id
      })
      return option.label
    }

    function onDestroy() {
      watchUnsaved(false)
    }
  }
})()
