;(function() {
  'use strict'

  angular.module('glow.reporting').factory('ReportSettings', Factory)

  /* @ngInject */
  function Factory() {
    function ReportSettings() {
      this.applyDefaults()
    }

    ReportSettings.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        marginOfError: {
          visible: false,
          populationSize: null,
          confidenceLevel: 95, // default to 95% confidence level
          responseLevel: 50, // fixed 50% response level (hidden from the UI)
          hideFromSharedReports: false,
        },
        errors: [],
      })
    }

    ReportSettings.prototype.deserialize = function(data) {
      data = _.isPlainObject(data) ? data : {}

      data.marginOfError = _.isPlainObject(data.marginOfError)
        ? data.marginOfError
        : {}
      this.marginOfError.visible = data.marginOfError.visible
      this.marginOfError.populationSize = data.marginOfError.populationSize
      this.marginOfError.confidenceLevel = data.marginOfError.confidenceLevel
      this.marginOfError.responseLevel = data.marginOfError.responseLevel
      this.marginOfError.hideFromSharedReports =
        data.marginOfError.hideFromSharedReports

      this.applyDefaults()
      return this
    }

    ReportSettings.prototype.serialize = function() {
      var data = {}

      data.marginOfError = {}
      data.marginOfError.visible = this.marginOfError.visible
      data.marginOfError.populationSize = this.marginOfError.populationSize
      data.marginOfError.confidenceLevel = this.marginOfError.confidenceLevel
      data.marginOfError.responseLevel = this.marginOfError.responseLevel
      data.marginOfError.hideFromSharedReports = this.marginOfError.hideFromSharedReports

      return data
    }

    ReportSettings.prototype.validate = function() {
      var errors = []

      if (this.marginOfError.visible) {
        if (!this.marginOfError.populationSize) {
          errors.push('Population Size is required to show Margin of Error')
        }
        if (!this.marginOfError.confidenceLevel) {
          errors.push('Confidence Level is required to show Margin of Error')
        }
      }

      this.errors = errors.length ? errors : null
      return !errors.length
    }

    return ReportSettings
  }
})()
