;(function() {
  'use strict'

  Controller.$inject = ["glDialog"];
  angular.module('glow.reporting').component('surveyExplorerSettingsDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-settings-dialog.html',
    bindings: {
      dashboard: '<',
      onApply: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(glDialog) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.apply = apply
    ctrl.cancel = cancel
    ctrl.remove = remove

    function onInit() {
      // ...
    }

    function apply() {
      ctrl.onApply({})
    }

    function cancel() {
      ctrl.onCancel()
    }

    function remove() {
      glDialog
        .confirm(
          'Delete Dashboard',
          'Are you sure you want to delete this dashboard? This action cannot be reverted.'
        )
        .then(function() {
          ctrl.onApply({ $remove: true })
        })
    }
  }
})()
