;(function() {
  'use strict'

  Controller.$inject = ["$state", "$q", "glAnalytics", "Currency", "StateFactory", "countryService", "currencyService", "userService", "subscriberService", "publicationService", "paymentMethodsService", "purchaseService"];
  angular.module('app.core').component('accountPurchase', {
    controller: Controller,
    templateUrl: 'account-purchase.html',
    bindings: {
      type: '<',
      currency: '<',
      bundle: '<',
      publication: '<',
      plan: '<',
      context: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $state,
    $q,
    glAnalytics,
    Currency,
    StateFactory,
    countryService,
    currencyService,
    userService,
    subscriberService,
    publicationService,
    paymentMethodsService,
    purchaseService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.requiresCreditCard = requiresCreditCard
    ctrl.getPlanPriceText = getPlanPriceText
    ctrl.getPlanDetails = getPlanDetails
    ctrl.getBundleDetails = getBundleDetails
    ctrl.getPublicationDetails = getPublicationDetails
    ctrl.setCountry = setCountry
    ctrl.isValidPurchase = isValidPurchase
    ctrl.purchase = purchase
    ctrl.done = done
    ctrl.submitLabel = submitLabel

    function onInit() {
      ctrl.mode = new StateFactory(['loading', 'form', 'success'])
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.currency = currencyService.getByCode(
        ctrl.subscriber.billing.currency || ctrl.currency || Currency.Codes.AUD
      )
      ctrl.isSSR = userService.isSSR()
      ctrl.countries = _.map(countryService.getAll(), function(country) {
        return {
          label: country.name,
          value: country.id,
          promoted: _.includes(['AU', 'GB', 'US'], country.cc),
        }
      })
      if (ctrl.type === 'bundle') {
        ctrl.title = 'Purchase Bundle'
      }
      if (ctrl.type === 'plan') {
        ctrl.title = makePlanDialogTitle(ctrl.plan)
      }
      if (ctrl.type === 'publication') {
        ctrl.title = 'Purchase Report'
      }
      if (ctrl.type === 'add-payment-method') {
        ctrl.title = 'Add Payment Method'
      }
      ctrl.mode.loading()
      loadPaymentMethod()
        .then(checkBillingInfo)
        .then(function() {
          ctrl.mode.form()
        })
      glAnalytics.track('purchase', 'display', ctrl.type)
    }

    function makePlanDialogTitle(plan) {
      var hasUsedTrial = ctrl.subscriber.usage.trials > 0
      var hasTrial = plan.trialDays > 0

      if (!hasTrial || hasUsedTrial) {
        return plan.name + ' Plan'
      }
      return 'Subscribe to ' + plan.name
      // return 'Try ' + plan.name + ' Free!'
    }

    function getPlanPriceText() {
      var hasUsedTrial = ctrl.subscriber.usage.trials > 0
      var price = ctrl.plan.price.byCurrency(ctrl.currency.code)
      var trialText =
        ctrl.plan.trialDays > 0
          ? hasUsedTrial
            ? ''
            : ' after your ' + ctrl.plan.trialDays + '-day trial'
          : ''

      return (
        price.format({ currency: true }) +
        ' per ' +
        ctrl.plan.interval.noun +
        '*' +
        trialText
      )
    }

    function getPlanDetails() {
      var hasUsedTrial = ctrl.subscriber.usage.trials > 0
      var hasTrial = ctrl.plan.trialDays > 0
      var text = 'You are purchasing the <b>' + ctrl.plan.name + '</b> plan. '
      var tax = {
        code: ctrl.plan.taxTypes[ctrl.currency.code],
        percent: ctrl.plan.taxPercentages[ctrl.currency.code],
      }
      var taxText = tax.percent ? ' + ' + tax.code : ''
      if (hasTrial && !hasUsedTrial) {
        text +=
          'Your free trial ends on <b>' +
          ctrl.plan.getTrialEndDate() +
          '</b>, after which you will be charged <b>' +
          ctrl.plan.price
            .byCurrency(ctrl.currency.code)
            .format({ currency: true }) +
          taxText +
          ' per ' +
          ctrl.plan.interval.noun +
          '</b>. '
      } else {
        text +=
          'You will be charged <b>' +
          ctrl.plan.price
            .byCurrency(ctrl.currency.code)
            .format({ currency: true }) +
          taxText +
          ' per ' +
          ctrl.plan.interval.noun +
          '</b>. '
      }
      text += 'No commitment, cancel any time.'
      return text
    }

    function getBundleDetails() {
      var tax = subscriberService.getTax(ctrl.currency.code)
      var text =
        'You are the purchasing the <b>' +
        ctrl.bundle.name +
        '</b> bundle for <b>' +
        ctrl.bundle.price
          .byCurrency(ctrl.currency.code)
          .format({ currency: true })
      if (tax.percent) {
        text += ' + ' + tax.code
      }
      text +=
        '</b>. The contents of this bundle will be immediately available after purchase.'
      return text
    }

    function getPublicationDetails() {
      var tax = subscriberService.getTax(ctrl.currency.code)
      var text =
        'You are the purchasing the <b>' +
        ctrl.publication.name +
        '</b> report for <b>' +
        ctrl.publication.price
          .byCurrency(ctrl.currency.code)
          .format({ currency: true })
      if (tax.percent) {
        text += ' + ' + tax.code
      }
      text +=
        '</b>. This report will become immediately available after purchase.'
      return text
    }

    function setCountry(countryId) {
      ctrl.subscriber.country = countryService.getById(countryId)
      if (!ctrl.subscriber.billing.currency) {
        var localCurrency = currencyService.getByCountry(
          ctrl.subscriber.country.cc
        )
        ctrl.currency =
          localCurrency || currencyService.getByCode(Currency.Codes.USD)
      }
    }

    function checkBillingInfo() {
      ctrl.hasBillingInfo =
        ctrl.subscriber.address &&
        ctrl.subscriber.city &&
        ctrl.subscriber.postcode &&
        ctrl.subscriber.state &&
        ctrl.subscriber.country
    }

    function isValidPurchase() {
      // billing details
      if (!ctrl.subscriber.address) {
        return false
      }
      if (!ctrl.subscriber.city) {
        return false
      }
      if (!ctrl.subscriber.postcode) {
        return false
      }
      if (!ctrl.subscriber.state) {
        return false
      }
      if (!ctrl.subscriber.country) {
        return false
      }
      if (
        requiresCreditCard() &&
        !ctrl.paymentMethod &&
        (!ctrl.card || !ctrl.card.$complete)
      ) {
        return false
      }
      return true
    }

    function requiresCreditCard() {
      var invoiced = ctrl.subscriber.billing.paymentType === 'INVOICE'
      if (invoiced) {
        return ctrl.type === 'bundle' || ctrl.type === 'publication'
      }
      return true
    }

    function purchase() {
      if (ctrl.isLoading || !ctrl.isValidPurchase()) {
        return
      }
      ctrl.errors = null
      ctrl.isLoading = true
      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .then(setCurrency)
        .then(savePaymentMethod)
        .then(function() {
          if (ctrl.type === 'bundle') {
            return purchaseBundle()
          }
          if (ctrl.type === 'plan') {
            return purchasePlan()
          }
          if (ctrl.type === 'publication') {
            return purchasePublication()
          }
        })
        .then(function() {
          if (ctrl.type === 'add-payment-method') {
            ctrl.onDone()
          } else {
            ctrl.mode.success()
          }
        })
        .catch(function(error) {
          var message =
            (error && error.message) ||
            'Something went wrong. Please try again.'
          ctrl.errors = [message]
        })
        .finally(function() {
          ctrl.isLoading = false
        })
    }

    function purchasePlan() {
      return subscriberService.subscribeToPlan(ctrl.plan)
    }

    function purchasePublication() {
      return publicationService.acquire(ctrl.publication)
    }

    function purchaseBundle() {
      return purchaseService.purchase(
        purchaseService.Types.BUNDLE,
        ctrl.bundle.id
      )
    }

    function setCurrency() {
      if (ctrl.subscriber.billing.currency) {
        return $q.resolve()
      }
      return subscriberService.setCurrency(ctrl.currency.code)
    }

    function loadPaymentMethod() {
      return paymentMethodsService
        .getBySubscriber(ctrl.subscriber.id)
        .then(function(paymentMethods) {
          // Only one payment method is supported
          ctrl.paymentMethod = paymentMethods[0]
          ctrl.hasSavedPaymentMethod = !!ctrl.paymentMethod
        })
    }

    function savePaymentMethod() {
      // SSR cannot modify a subscriber's payment method so just bypass payment method
      if (ctrl.isSSR || ctrl.paymentMethod || !requiresCreditCard()) {
        return $q.resolve()
      }
      return ctrl.card
        .submit()
        .then(function() {
          var action = ctrl.hasSavedPaymentMethod ? 'replace' : 'create'
          return paymentMethodsService[action](
            ctrl.subscriber.id,
            ctrl.card.tokenId
          )
        })
        .catch(function(error) {
          return {
            message: 'There was an issue saving your payment method',
            info: error,
          }
        })
    }

    function submitLabel() {
      if (ctrl.type === 'plan') {
        return ctrl.subscriber.usage.trials > 0 ? 'Start' : 'Start Trial'
      }
      if (ctrl.type === 'bundle') {
        return 'Purchase'
      }
      if (ctrl.type === 'publication') {
        return 'Purchase'
      }
      if (ctrl.type === 'add-payment-method') {
        return 'Add'
      }
    }

    function done() {
      ctrl.onDone()
      if (
        ctrl.type === 'bundle' ||
        ctrl.context === 'channel-rewards' ||
        ctrl.context === 'channel-panel'
      ) {
        subscriberService.loadSubscriber(ctrl.subscriber.id)
      } else {
        $state.reload()
      }
    }
  }
})()
