;(function() {
  'use strict'

  Service.$inject = ["$q", "$state", "$window", "glDialog", "configService", "subscriberService", "userService", "glAuthService"];
  angular.module('app.core').service('accountService', Service)

  /* @ngInject */
  function Service(
    $q,
    $state,
    $window,
    glDialog,
    configService,
    subscriberService,
    userService,
    glAuthService
  ) {
    var self = this

    self.showAuthDialog = showAuthDialog
    self.showPurchaseDialog = showPurchaseDialog
    self.showDowngradeDialog = showDowngradeDialog
    self.showResponsePricingDialog = showResponsePricingDialog
    self.showSubscriberRenamerDialog = showSubscriberRenamerDialog
    self.showSubscriberCreatorDialog = showSubscriberCreatorDialog
    self.showUacEditorDialog = showUacEditorDialog
    self.promptUpgrade = promptUpgrade
    self.signup = signup

    function showAuthDialog(mode, context) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-auth__dialog">',
          '<account-auth',
            'mode="mode"',
            'context="context"',
            'on-done="dialog.close($outcome)"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        locals: {
          mode: mode,
          context: context,
        },
      })
    }

    function showPlansDialog(options) {
      options = _.defaults(options, { context: null })
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-plans__dialog">',
          '<g-dialog-header',
            "title=\"{{ context === 'welcome' ? 'Welcome to Glow' : 'Plans' }}\"",
            'on-close="dialog.cancel()"',
          '></g-dialog-header>',
          '<account-plans',
            'context="context"',
            'on-select="dialog.close($plan)"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        locals: { context: options.context },
      })
    }

    function showPurchaseDialog(options) {
      options = _.defaults(options, {
        type: null,
        currency: null,
        bundle: null,
        publication: null,
        plan: null,
        context: null,
      })

      // only show alert dialog for the following types
      var message = 'Please ask your organisation owner to do this.'
      var alerts = {
        'add-payment-method': {
          title: 'No Payment Method',
          message:
            'Please ask your organisation owner to add payment method in the Account -> Billing section',
        },
        plan: {
          title: 'Cannot Upgrade Plan',
          message: message,
        },
        bundle: {
          title: 'Cannot Purchase Bundle',
          message: message,
        },
        publication: {
          title: 'Cannot Purchase Report',
          message: message,
        },
      }
      var alert = alerts[options.type]
      var isAdmin = subscriberService.isAdmin(userService.getUser().id)
      if (!isAdmin && alert) {
        glDialog.alert(alert.title, alert.message)
        return $q.reject()
      }

      // prettier-ignore
      var template = [
        '<gl-dialog class="account-purchase__dialog">',
          '<account-purchase',
            'type="type" ',
            'currency="currency" ',
            'bundle="bundle" ',
            'publication="publication" ',
            'plan="plan" ',
            'context="context" ',
            'on-done="dialog.close()" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        locals: {
          type: options.type,
          currency: options.currency,
          bundle: options.bundle,
          publication: options.publication,
          plan: options.plan,
          context: options.context,
        },
      })
    }

    function showDowngradeDialog(plan) {
      var isAdmin = subscriberService.isAdmin(userService.getUser().id)
      if (!isAdmin) {
        glDialog.alert(
          'Cannot Downgrade Plan',
          'Please ask your organisation owner to do this.'
        )
        return $q.reject()
      }

      var message =
        'Your plan will be downgraded at the end of this billing cycle, ' +
        'after which you will no longer be charged a plan fee.'
      return glDialog
        .confirm('Downgrade Plan', message, {
          locals: { closeText: 'Confirm' },
        })
        .then(function() {
          return subscriberService.subscribeToPlan(plan)
        })
        .then($state.reload)
    }

    function showResponsePricingDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-response-pricing__dialog">',
          '<g-dialog-header',
            'title="Response Pricing"',
            'on-close="dialog.close()"',
          '></g-dialog-header>',
          '<account-response-pricing show-caption="true" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: true,
        escapeToClose: true,
      })
    }

    function showSubscriberRenamerDialog() {
      var user = userService.getUser()
      var subscriber = subscriberService.getSubscriber()

      if (subscriber.name !== user.fullname) {
        return $q.resolve()
      }

      // prettier-ignore
      var template = [
        '<gl-dialog class="account-subscriber-renamer__dialog">',
          '<account-subscriber-renamer',
            'subscriber="subscriber"',
            'on-done="dialog.close()"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          subscriber: subscriber,
        },
      })
    }

    function showSubscriberCreatorDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-subscriber-creator__dialog">',
          '<account-subscriber-creator',
            'on-done="dialog.close()"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {},
      })
    }

    function showUacEditorDialog(member) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-uac-editor__dialog">',
          '<account-uac-editor',
            'member="member"',
            'on-done="dialog.close()"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          member: member,
        },
      })
    }

    function promptUpgrade(options) {
      options = _.defaults(options, { context: null, bypassCondition: null })
      // if there is a bypassCondition, resolve
      if (options.bypassCondition) {
        return $q.resolve()
      }

      // only show alert dialog for the following contexts
      var alerts = {
        'channel-panel': 'Cannot Start Panel Channel',
        'channel-rewards': 'Cannot Add Rewards',
        'clone-survey': 'Cannot Clone Survey',
        'export-responses': 'Cannot Export Responses',
        'more-surveys': 'Cannot Create More Surveys',
        'more-projects': 'Cannot Create More Projects',
        'premium-publication': 'Cannot Acquire Premium Report',
      }
      var alertTitle = alerts[options.context]
      var isAdmin = subscriberService.isAdmin(userService.getUser().id)
      if (!isAdmin && alertTitle) {
        glDialog.alert(
          alertTitle,
          "Your organisation plan doesn't have this functionality. Please ask your organisation owner to upgrade the plan in the Account -> Plan section"
        )
        return $q.reject()
      }

      return showPlansDialog({ context: options.context }).then(function(plan) {
        if (!plan.isFree()) {
          return showPurchaseDialog({
            type: 'plan',
            plan: plan,
            context: options.context,
          })
        } else {
          // TODO: remove this block?
          // If there is only ever one free plan, which will be the case for the
          // foreseeable future, then this will never be reached.
          showDowngradeDialog(plan).then(function() {
            var downgradeUrl = configService.getDowngradeUrl()
            if (downgradeUrl) {
              $window.open(downgradeUrl, '_blank')
            }
          })
          return $q.reject()
        }
      })
    }

    // Sign up with a password
    // TODO: replace this with a single BE command
    function signup(user, subscriber) {
      var permittedRoles = [
        glAuthService.Roles.SUBSCRIBER,
        glAuthService.Roles.SUBSCRIBER_SUPPORT,
      ]
      return (
        userService
          .hasRoles(user.email, permittedRoles)
          // 1. Check if user exists
          .then(function(hasRoles) {
            return hasRoles ? $q.reject({ status: 409 }) : $q.resolve()
          })
          // 2. Create an email user in preparation for subsequent activation
          .then(function() {
            return userService.createEmailUser(user)
          })
          // 3. Create subscriber
          .then(function(response) {
            var options = { auth: { token: response.token } }
            return subscriberService.create(subscriber, options)
          })
          // 4. Activate the user with password to trigger BE events for AC
          .then(function() {
            return userService.create(user)
          })
          // 5. Login the new user
          .then(function() {
            return userService.login(user)
          })
          // 6. Load their subscriber details
          .then(function() {
            var subscriberId = userService.getSubscriberId()
            return subscriberService.loadSubscriber(subscriberId)
          })
      )
    }
  }
})()
