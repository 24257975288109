;(function() {
  'use strict'

  subscriberService.$inject = ["AUTH_EVENTS", "$rootScope", "$log", "$q", "api", "glAnalytics", "subscribersResource", "subscriberActivationsResource", "subscriberUsersResource", "imagesService", "plansService", "channelService", "ThemeContext", "Survey", "Project", "Place", "Product", "Money"];
  angular
    .module('core.services')
    .factory('subscriberService', subscriberService)

  /**
   * subscriberService
   * - handle all subscriber activity and interations
   *
   * @ngInject */
  function subscriberService(
    AUTH_EVENTS,
    $rootScope,
    $log,
    $q,
    api,
    glAnalytics,
    subscribersResource,
    subscriberActivationsResource,
    subscriberUsersResource,
    imagesService,
    plansService,
    channelService,
    ThemeContext,
    Survey,
    Project,
    Place,
    Product,
    Money
  ) {
    $log = $log.create('subscriberService')

    var currentSubscriber // subscriber object
    var Events = {
      CHANGE: 'subscriberService:CHANGE',
    }

    $rootScope.$on(AUTH_EVENTS.logoutSuccess, function() {
      setSubscriber(null)
    })
    // Update cached values
    $rootScope.$on(Survey.Events.CREATED, _.partial(incrementUsage, 'surveys'))
    $rootScope.$on(
      Project.Events.CREATED,
      _.partial(incrementUsage, 'projects')
    )
    $rootScope.$on(Place.Events.CREATED, _.partial(incrementUsage, 'places'))
    $rootScope.$on(
      Product.Events.CREATED,
      _.partial(incrementUsage, 'products')
    )

    channelService.setCurrencyGetter(function() {
      return currentSubscriber && currentSubscriber.billing.currency
    })

    return {
      create: create,
      activateViaToken: activateViaToken,
      createSubscriber: createSubscriber,
      loadSubscriber: loadSubscriber,
      getSubscriber: getSubscriber,
      refresh: refresh,
      getSubscriberUsers: getSubscriberUsers,
      subscribeToPlan: subscribeToPlan,
      requestInvoice: requestInvoice,
      removePremiumToken: removePremiumToken,
      getTax: getTax,
      saveSubscriber: saveSubscriber,
      saveWithImages: saveWithImages,
      setCurrency: setCurrency,
      isFrozen: isFrozen,
      isAdmin: isAdmin,
      getInvite: getInvite,
      consumeInvite: consumeInvite,
      sendInvite: sendInvite,
      removeMember: removeMember,
      updateMemberUAC: updateMemberUAC,
      getMemberById: getMemberById,
      getMemberByUserId: getMemberByUserId,
      Events: Events,
    }

    function incrementUsage(property) {
      if (
        currentSubscriber &&
        currentSubscriber.usage &&
        _.isNumber(currentSubscriber.usage[property])
      ) {
        currentSubscriber.usage[property]++
      }
    }

    /**
     * @name create
     * @desc create a subscriber
     * @param {Object} data - The subscriber data
     * @param {Object} options - The resource options
     * @returns {Promise}
     */
    function create(data, options) {
      return $q(function(resolve, reject) {
        subscribersResource
          .createWithCurrentUser(data, null, options)
          .success(function(x, data) {
            resolve(data)
          })
          .error(reject)
      })
    }

    /**
     * @name activateViaToken
     * @description activate a subscriber user account via a token
     * @param  {Object} data subsciber activation object - including token
     * @return {Promise} promise
     */
    function activateViaToken(data) {
      var deferred = $q.defer()

      subscriberActivationsResource
        .createUserViaToken(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name createSubscriber
     * @description create a new subscriber --> this only applies when registering with a valid place
     * @param {Object} data subscriber object
     * @return {Promise} promise
     */
    function createSubscriber(data) {
      var deferred = $q.defer()

      subscribersResource
        .create(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name loadSubsciber
     * @description get subscriber details from glow service
     * @param {String} id subscriber id
     * @return {Promise} promise
     */
    function loadSubscriber(id) {
      var deferred = $q.defer()

      subscribersResource
        .get({ id: id })
        .success(loadSuccess)
        .error(deferred.reject)

      function loadSuccess(data) {
        if (!_.isPlainObject(data.data)) data.data = {}
        plansService.get(data.planDetail.planId).then(function(plan) {
          data.plan = plan
          data.portalTheme = data.portalTheme || {}
          data.freeze = _.isPlainObject(data.freeze) ? data.freeze : {}
          data.data = _.isPlainObject(data.data) ? data.data : {}

          // feature flags
          data.data.flags = _.isPlainObject(data.data.flags)
            ? data.data.flags
            : {}

          // lazy migration: https://engagementinnovation.atlassian.net/wiki/spaces/~ash.connell/pages/1074954241/External+Panel
          if (data.data.allowAddIntegratedPanel === undefined) {
            data.data.allowAddIntegratedPanel = true
            data.data.allowAddExternalPanel = true
            data.data.preferredExternalPanelId = null
          }

          // HACK: subscribers shouldn't have threshold/unpaidBalance/credit
          // before they pick a currency but we need to keep to the model,
          // so we fallback to AUD and update them when the subscriber
          // sets their currency
          var currency = data.billing.currency || 'AUD'
          data.billing = {
            paymentType: data.billing.paymentType,
            currency: data.billing.currency,
            threshold: new Money({
              amount: data.billing.threshold,
              currency: currency,
            }),
            unpaidBalance: new Money({
              amount: data.billing.thresholdBalance,
              currency: currency,
            }),
            credit: new Money({
              amount: data.billing.accountBalance,
              currency: currency,
            }),
            balance: new Money({
              amount: _.clamp(
                data.billing.accountBalance - data.billing.thresholdBalance,
                0,
                Infinity
              ),
              currency: currency,
            }),
            matchedSpend: {
              limit: new Money({
                amount: data.billing.matchingSpend.limit,
                currency: currency,
              }),
              used: new Money({
                amount: data.billing.matchingSpend.currentSpend,
                currency: currency,
              }),
              remaining: new Money({
                amount:
                  data.billing.matchingSpend.limit -
                  data.billing.matchingSpend.currentSpend,
                currency: currency,
              }),
            },
          }
          setSubscriber(data)
          deferred.resolve(data)
        })
      }

      return deferred.promise
    }

    function refresh() {
      return loadSubscriber(currentSubscriber.id)
    }

    /**
     * @name getSubsciber
     * @description get currently loaded subscriber - throw error if none exists
     * @return {Promise} promise
     */
    function getSubscriber() {
      return currentSubscriber
    }

    /**
     * @name getSubscriberUsers
     * @description get all users linked to a subscriber
     * @param {String} id subscriber id
     * @return {Array} array of users
     */
    function getSubscriberUsers(id) {
      var deferred = $q.defer()

      subscriberUsersResource
        .query({ id: id })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    function subscribeToPlan(plan) {
      if (!currentSubscriber) return $q.reject()
      return api.subscribers
        .subscribeToPlan({ id: currentSubscriber.id, planId: plan.id })
        .then(refresh)
        .then(function() {
          $rootScope.$emit(Events.CHANGE, currentSubscriber)
          var action = _.kebabCase('subscribe-to-' + plan.name + '-success')
          glAnalytics.track('plans', action)
        })
    }

    function requestInvoice() {
      return $q(function(resolve, reject) {
        subscribersResource
          .requestToBeInvoiced({ id: currentSubscriber.id })
          .success(function() {
            resolve()
          })
          .error(function() {
            reject()
          })
      })
    }

    /**
     * @name saveSubscriber
     * @description save subscriber data to glow service
     * @param {Object} subscriber subscriber object
     * @return {Promise} promise
     */
    function saveSubscriber(subscriber) {
      return $q(function(resolve, reject) {
        // serialize theme context
        var themeContext = subscriber.themeContext.serialize()
        var data = _.cloneDeep(subscriber)
        data.themeContext = undefined
        data.data.themeContext = themeContext

        subscribersResource
          .save(data)
          .success(function(response) {
            setSubscriber(data)
            resolve(response)
          })
          .error(reject)
      })
    }

    /**
     * @name saveWithImages
     * @description upload images and update subscriber - this is different to save in that the images
     * have not been uploaded yet and are in raw base64 format instead of a URL string
     * @param  {Object} subscriber subscriber object to save
     * @return {Promise}
     */
    function saveWithImages(subscriber) {
      var deferred = $q.defer()

      // first upload the images so we can get the URL's to use for the page
      handleImageUploads(subscriber).then(function(data) {
        // set and remove image data
        if (data[0]) {
          subscriber.portalTheme.logoImageUrl = data[0].imageUrl
        }
        if (data[1]) {
          subscriber.portalTheme.featureImageUrl = data[1].imageUrl
        }

        // save subscriber
        subscribersResource
          .save(subscriber)
          .success(createSuccess)
          .error(deferred.reject)
      })

      return deferred.promise

      function createSuccess() {
        setSubscriber(subscriber)
        return deferred.resolve()
      }
    }

    function setCurrency(code) {
      return $q(function(resolve, reject) {
        subscribersResource
          .setCurrency({ id: currentSubscriber.id, currency: code })
          .success(function(response) {
            currentSubscriber.billing.currency = code
            currentSubscriber.billing.threshold.currency = code
            currentSubscriber.billing.unpaidBalance.currency = code
            currentSubscriber.billing.credit.currency = code
            resolve(response)
          })
          .error(reject)
      })
    }

    function removePremiumToken(token) {
      if (!currentSubscriber) {
        return
      }
      _.remove(currentSubscriber.premiumTokens, function(premiumToken) {
        return premiumToken === token
      })
    }

    function getTax(fallbackCurrency) {
      var tax = {}
      var currency = currentSubscriber.billing.currency || fallbackCurrency
      tax.code = currentSubscriber.plan.taxTypes[currency]
      tax.percent = currentSubscriber.plan.taxPercentages[currency]
      return tax
    }

    function setSubscriber(subscriber) {
      if (subscriber) {
        if (!subscriber.premiumTokens) {
          subscriber.premiumTokens = []
        }
        if (subscriber.addPackTokens) {
          subscriber.premiumTokens = []
          _.each(subscriber.addPackTokens, function(token) {
            if (!token.redeemed) {
              subscriber.premiumTokens.push(token.token)
            }
          })
          subscriber.addPackTokens = null
        }
        if (subscriber.industry) {
          glAnalytics.set(
            glAnalytics.Dimensions.INDUSTRY,
            subscriber.industry.code
          )
        }
        if (subscriber.city) {
          glAnalytics.set(glAnalytics.Dimensions.CITY, subscriber.city)
        }
        if (subscriber.state) {
          glAnalytics.set(glAnalytics.Dimensions.STATE, subscriber.state)
        }
        if (subscriber.postcode) {
          glAnalytics.set(glAnalytics.Dimensions.POSTCODE, subscriber.postcode)
        }
        if (subscriber.plan) {
          glAnalytics.set(
            glAnalytics.Dimensions.PLAN,
            _.upperCase(subscriber.plan.name)
          )
        }
        if (subscriber.country) {
          glAnalytics.set(glAnalytics.Dimensions.COUNTRY, subscriber.country.cc)
        }
        if (!subscriber.accountManager) {
          subscriber.accountManager = {
            userId: '',
            hubId: '',
          }
        }

        // deserialize theme context
        subscriber.themeContext = new ThemeContext()
          .asSubscriberDefault()
          .deserialize(_.get(subscriber, 'data.themeContext'))
      }
      currentSubscriber = subscriber
      $rootScope.$emit(Events.CHANGE, currentSubscriber)
    }

    function isFrozen() {
      var subscriber = getSubscriber()
      return subscriber && subscriber.freeze.isEnabled
    }

    function isAdmin(userId) {
      var subscriber = getSubscriber()
      return subscriber.administrator.userId === userId
    }

    function getInvite(token) {
      return api.invites.get({ token: token })
    }

    function consumeInvite(token) {
      return api.invites.consume({ token: token })
    }

    function sendInvite(email) {
      return $q(function(resolve, reject) {
        var defaultPermissions = {
          canManageUsers: false,
          canManageProjects: true,
          canManageSurveys: true,
          canManageChannels: true,
          canLaunchChannels: true,
          canManageAnalysisFilters: false,
          canManageAnalysisSettings: false,
          canExportAndShareAnalysis: true,
          canViewAnalysisCrosstabs: true,
          canManageAnalysisCrosstabs: false,
          canViewAnalysisResponses: true,
          canManageAnalysisResponses: false,
          canManageReports: false,
        }
        var inviteData = _.assign(
          { id: getSubscriber().id, email: email },
          defaultPermissions
        )
        api.subscriberMembers
          .invite(inviteData)
          .then(function(data) {
            // optimistically update subscriber.members
            var newMember = _.assign(
              {
                id: data.memberId,
                email: data.email,
              },
              defaultPermissions
            )
            getSubscriber().members.push(newMember)
            resolve()
          })
          .catch(reject)
      })
    }

    function removeMember(memberId) {
      return $q(function(resolve, reject) {
        api.subscriberMembers
          .remove({
            id: getSubscriber().id,
            memberId: memberId,
          })
          .then(function() {
            // optimistically update subscriber.members
            _.remove(getSubscriber().members, { id: memberId })
            resolve()
          })
          .catch(reject)
      })
    }

    function updateMemberUAC(member) {
      return $q(function(resolve, reject) {
        api.subscriberMembers
          .updateUAC({
            id: getSubscriber().id,
            memberId: member.id,
            canManageUsers: member.canManageUsers,
            canManageProjects: member.canManageProjects,
            canManageSurveys: member.canManageSurveys,
            canManageChannels: member.canManageChannels,
            canLaunchChannels: member.canLaunchChannels,
            canManageAnalysisFilters: member.canManageAnalysisFilters,
            canManageAnalysisSettings: member.canManageAnalysisSettings,
            canExportAndShareAnalysis: member.canExportAndShareAnalysis,
            canViewAnalysisCrosstabs: member.canViewAnalysisCrosstabs,
            canManageAnalysisCrosstabs: member.canManageAnalysisCrosstabs,
            canViewAnalysisResponses: member.canViewAnalysisResponses,
            canManageAnalysisResponses: member.canManageAnalysisResponses,
            canManageReports: member.canManageReports,
          })
          .then(function(data) {
            // optimistically update subscriber.members
            _.assign(getMemberById(data.id), data)
            resolve()
          })
          .catch(reject)
      })
    }

    function getMemberById(id) {
      return _.find(getSubscriber().members, { id: id })
    }

    function getMemberByUserId(userId) {
      return _.find(getSubscriber().members, { userId: userId })
    }

    /**
     * @name handleImageUploads when setting up and subscriber account
     * @description queue image uploads and return a promise
     * @param  {Object} subscriber subscriber data object
     * @return {Promise}
     */
    function handleImageUploads(subscriber) {
      var logo = buildPageImageData(
        subscriber.portalTheme.logoImageUrl,
        'account.setup'
      )
      var featureImage = buildPageImageData(
        subscriber.portalTheme.featureImageUrl,
        'account.setup'
      )

      var imageUploads = [
        imagesService.create(logo),
        imagesService.create(featureImage),
      ]

      return $q.all(imageUploads)
    }

    /**
     * @name buildPageImageData
     * @description take image data and return a structured object to be sent to the API
     * @param  {String} imageData base64 image data
     * @param  {String} request   the opperation making the request to upload an image
     * @return {Object}           structured image upload object
     */
    function buildPageImageData(imageData, request) {
      if (!imageData) {
        return null
      }

      return {
        groupId: currentSubscriber.id,
        imageData: imageData,
        request: request,
        source: 'portal.glowfeed.com',
      }
    }
  }
})()
