;(function() {
  'use strict'

  Factory.$inject = ["Question", "ThemeContext", "glUtils", "numericService"];
  angular.module('app.core').factory('DashboardView', Factory)

  /* @ngInject */
  function Factory(Question, ThemeContext, glUtils, numericService) {
    function DashboardView() {
      this.applyDefaults()
    }

    DashboardView.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: glUtils.uuid(),
        type: null,
        label: null,
        private: false,
        loops: [],
        choices: [],
        statements: [],
        ranks: [],
        slots: {
          loop: null,
          target: null,
          rows: null,
          columns: null,
          format: null,
        },
        configs: {
          choices: {
            items: [],
            combineWeighted: false,
            combineWeightedLabel: 'Total',
          },
          statements: {
            items: [],
          },
          ranks: {
            items: [],
          },
          loops: {
            items: [],
          },
          filters: {
            items: [],
          },
        },
        chart: {
          title: '',
          stacked: true,
          rotate: false,
        },
        table: {
          absolute: false,
        },
        list: {
          query: '',
        },
        map: {
          isMarkers: false,
        },
        prefilters: [],
        questionId: null,
        questionType: null,
        theme: new ThemeContext().asQuestionDefault(),
      })
    }

    DashboardView.prototype.deserialize = function(data) {
      var self = this

      this.id = data.id
      this.type = data.type
      this.label = data.label
      this.private = data.private
      this.loops = data.loops
      this.choices = data.choices
      this.statements = data.statements
      this.ranks = data.ranks
      this.slots = {}
      this.slots.loop = data.slots.loop
      this.slots.target = data.slots.target
      this.slots.rows = data.slots.rows
      this.slots.columns = data.slots.columns
      this.slots.format = data.slots.format
      this.configs = {}
      this.configs.choices = {}
      this.configs.choices.items = data.configs.choices.items
      this.configs.choices.combineWeighted =
        data.configs.choices.combineWeighted
      this.configs.choices.combineWeightedLabel = data.configs.choices.combineWeightedLabel // prettier-ignore
      this.configs.statements = {}
      this.configs.statements.items = data.configs.statements.items
      this.configs.ranks = {}
      this.configs.ranks.items = data.configs.ranks.items
      this.configs.loops = {}
      this.configs.loops.items = data.configs.ranks.items
      this.configs.filters = {}
      this.configs.filters.items = data.configs.filters.items
      this.chart = {}
      this.chart.title = data.chart.title
      this.chart.stacked = data.chart.stacked
      this.chart.rotate = data.chart.rotate
      this.table = {}
      this.table.absolute = data.table.absolute
      this.list = {}
      this.list.query = data.list.query || ''
      this.map = {}
      this.map.isMarkers = data.map && data.map.isMarkers
      this.prefilters = data.prefilters
      this.questionId = data.questionId
      this.questionType = data.questionType
      this.theme = new ThemeContext()
        .asQuestionDefault()
        .deserialize(data.theme)

      this.applyDefaults()
      return this
    }

    DashboardView.prototype.serialize = function() {
      var data = {}

      data.id = this.id
      data.type = this.type
      data.label = this.label
      data.private = this.private
      data.loops = this.loops.slice()
      data.choices = this.choices.slice()
      data.statements = this.statements.slice()
      data.ranks = this.ranks.slice()
      data.slots = {}
      data.slots.loop = this.slots.loop
      data.slots.target = this.slots.target
      data.slots.rows = this.slots.rows
      data.slots.columns = this.slots.columns
      data.slots.format = this.slots.format
      data.configs = {}
      data.configs.choices = {}
      data.configs.choices.items = this.configs.choices.items.slice()
      data.configs.choices.combineWeighted = this.configs.choices.combineWeighted
      data.configs.choices.combineWeightedLabel = this.configs.choices.combineWeightedLabel
      data.configs.statements = {}
      data.configs.statements.items = this.configs.statements.items.slice()
      data.configs.ranks = {}
      data.configs.ranks.items = this.configs.ranks.items.slice()
      data.configs.loops = {}
      data.configs.loops.items = this.configs.loops.items.slice()
      data.configs.filters = {}
      data.configs.filters.items = this.configs.filters.items.slice()
      data.chart = {}
      data.chart.title = this.chart.title
      data.chart.stacked = this.chart.stacked
      data.chart.rotate = this.chart.rotate
      data.table = {}
      data.table.absolute = this.table.absolute
      data.list = {}
      data.list.query = this.list.query
      data.map = {}
      data.map.isMarkers = this.map.isMarkers
      data.prefilters = this.prefilters.slice()
      data.questionId = this.questionId
      data.questionType = this.questionType
      data.theme = this.theme.serialize()

      return data
    }

    DashboardView.prototype.fromQuestion = function(question) {
      var self = this
      this.type = 'question'
      this.label = 'Q' + question.getNumber() + '. ' + question.title
      this.choices = question.getAllChoices().map(function(opt) {
        return { id: opt.id, label: opt.label }
      })
      if (question.type === Question.Types.NUMERIC) {
        this.choices = numericService
          .calculateValues(
            question.numericMin,
            question.numericMax,
            question.numericSteps,
            question.numericUnit,
            question.numericLabels
          )
          .map(function(value) {
            return {
              id: value.number + '',
              label: value.label,
            }
          })
      }
      this.statements = question.getAllStatements().map(function(opt) {
        return { id: opt.id, label: opt.label }
      })
      this.ranks = []
      if (question.type === Question.Types.RANK) {
        var numRanks = question.getNumberOfRanks()
        _.times(numRanks, function(n) {
          self.ranks.push({
            id: n + 1 + '',
            label: `Rank ${n + 1}`,
          })
        })
      }
      this.loops = []
      question.survey.loops.forEach(function(loop) {
        if (loop.questionIds.includes(question.id)) {
          loop.keys.forEach(function(key) {
            self.loops.push({
              id: key.id,
              label: key.label,
            })
          })
        }
      })

      this.chart.title = question.title
      this.chart.rotate = false
      this.chart.stacked = false // rank, matrix and score are changed below

      switch (question.type) {
        case Question.Types.CHOICE:
        case Question.Types.MOOD:
        case Question.Types.NPS:
        case Question.Types.SCALE:
        case Question.Types.RATING:
        case Question.Types.NUMERIC:
        case Question.Types.HIDDEN_VARIABLES:
          this.slots = {
            loop: this.loops.length ? this.loops[0].id : null,
            target: null,
            rows: 'choices',
            columns: 'filters',
            format: 'col-percent',
          }
          break
        case Question.Types.MATRIX:
          this.slots = {
            loop: this.loops.length ? this.loops[0].id : null,
            target: null,
            rows: 'statements',
            columns: 'choices',
            format: 'row-percent',
          }
          this.chart.stacked = true
          break
        case Question.Types.RANK:
          this.slots = {
            loop: this.loops.length ? this.loops[0].id : null,
            target: null,
            rows: 'choices',
            columns: 'ranks',
            format: 'total-percent',
          }
          this.chart.stacked = true
          break
        case Question.Types.SCORE:
          this.slots = {
            loop: null,
            target: null,
            rows: 'filters',
            columns: 'choices',
            format: 'average',
            // question.type === Question.Types.CONSTANT_SUM
            //   ? 'col-percent'
            //   : 'count-percent',
          }
          if (question.matrixEnabled) {
            this.slots.rows = 'statements'
            this.slots.columns = 'choices'
          }
          this.chart.stacked = false
          break
        case Question.Types.CONSTANT_SUM:
          this.slots = {
            loop: null,
            target: null,
            rows: 'choices',
            columns: 'filters',
            format: 'average',
            // question.type === Question.Types.CONSTANT_SUM
            //   ? 'col-percent'
            //   : 'count-percent',
          }
          if (question.matrixEnabled) {
            this.slots.rows = 'statements'
            this.slots.columns = 'choices'
          }
          break
        case Question.Types.TEXT:
        case Question.Types.SINGLE_TEXT:
        case Question.Types.SCAN:
        case Question.Types.LOCATION:
          this.slots = {
            loop: this.loops.length ? this.loops[0].id : null,
            target: null,
            rows: null,
            columns: null,
            format: null,
          }
          break
        case Question.Types.PLACES_NEAR_ME:
        case Question.Types.IMAGE:
        case Question.Types.FOLLOW_UP:
          break
      }

      if (question.type === Question.Types.NPS) {
        var groups = [
          {
            name: 'Detractors (0-6)',
            choices: ['0', '1', '2', '3', '4', '5', '6'],
          },
          {
            name: 'Passives (7-8)',
            choices: ['7', '8'],
          },
          {
            name: 'Promoters (9-10)',
            choices: ['9', '10'],
          },
        ]
        this.configs.choices.items = []
        for (var group of groups) {
          var optionIds = this.choices
            .filter(c => group.choices.includes(c.label))
            .map(c => c.id)
          this.configs.choices.items.push({
            id: group.name,
            label: group.name,
            weight: null,
            optionIds: optionIds,
            redact: null,
          })
        }
      } else if (question.type === Question.Types.SCORE) {
        let weight = -100
        this.configs.choices.combineWeighted = true
        this.configs.choices.combineWeightedLabel = question.scoreLabel
        this.configs.choices.items = this.choices.map(function(opt) {
          const isDontKnow = opt.label === `Don't know`
          const mod = {
            id: opt.id,
            label: opt.label,
            weight: weight,
            optionIds: [opt.id],
            redact: isDontKnow ? 'exclude' : null,
          }
          weight += 20
          if (weight === 120) weight = null
          return mod
        })
      } else {
        this.configs.choices.items = this.choices.map(function(opt) {
          return {
            id: opt.id,
            label: opt.label,
            weight: null,
            optionIds: [opt.id],
            redact: null,
          }
        })
      }
      this.configs.statements.items = this.statements.map(function(opt) {
        return {
          id: opt.id,
          label: opt.label,
          weight: null,
          optionIds: [opt.id],
          exclude: false,
          hide: false,
        }
      })
      this.configs.ranks.items = this.ranks.map(function(opt) {
        return {
          id: opt.id,
          label: opt.label,
          weight: null,
          optionIds: [opt.id],
          exclude: false,
          hide: false,
        }
      })
      this.configs.loops.items = this.loops.map(function(opt) {
        return {
          id: opt.id,
          label: opt.label,
          weight: null,
          optionIds: [opt.id],
          exclude: false,
          hide: false,
        }
      })

      this.table.absolute = false

      this.list.query = ''
      this.map.isMarkers = false

      this.questionId = question.id
      this.questionType = question.type

      return this
    }

    DashboardView.prototype.clone = function() {
      return new DashboardView().deserialize(this.serialize())
    }

    DashboardView.prototype.duplicate = function() {
      var view = this.clone()
      view.id = glUtils.uuid()
      return view
    }

    return DashboardView
  }
})()
