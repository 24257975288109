;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "accountService", "featureService", "subscriberService", "userService"];
  angular.module('app.core').component('surveyReportQuota', {
    controller: Controller,
    templateUrl: 'survey-report-quota.html',
  })

  /* @ngInject */
  function Controller(
    glUtils,
    accountService,
    featureService,
    subscriberService,
    userService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.upgrade = upgrade

    function onInit() {
      ctrl.unlimited = featureService.hasUnlimitedAnalysisHistory()

      if (!ctrl.unlimited) {
        ctrl.daysText = daysText(featureService.getAnalysisHistoryDays())
        ctrl.canUpgrade = featureService.canUpgrade()
        ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      }
    }

    function daysText(days) {
      return days + glUtils.numberSwitch(days, ' day', ' days')
    }

    function upgrade() {
      accountService.promptUpgrade({ context: 'more-online-analysis' })
    }
  }
})()
