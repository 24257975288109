;(function() {
  'use strict'

  Controller.$inject = ["$state", "glToast", "loiService", "estimateService", "panelService", "userService", "accountService", "subscriberService", "plansService"];
  angular.module('app.core').controller('Estimate', Controller)

  /* @ngInject */
  function Controller(
    $state,
    glToast,
    loiService,
    estimateService,
    panelService,
    userService,
    accountService,
    subscriberService,
    plansService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setType = setType
    ctrl.setLoi = setLoi
    ctrl.selectPlan = selectPlan
    ctrl.setCountry = setCountry
    ctrl.setLanguage = setLanguage
    ctrl.create = create
    ctrl.checkFeasibility = checkFeasibility
    ctrl.email = email
    ctrl.clampIncidenceRate = clampIncidenceRate
    ctrl.clearEstimate = clearEstimate
    ctrl.createSurvey = createSurvey
    ctrl.goToKits = goToKits

    function onInit() {
      ctrl.languageOptionsByCountry = []
      ctrl.EstimateTypes = estimateService.Types
      ctrl.estimateTypes = estimateService.TypeInfo
      ctrl.loiOptions = loiService.types.map(function(type) {
        return {
          label: type.rangeLabel,
          value: type.estimateSeconds,
        }
      })
      panelService.getCountries().then(function(countries) {
        ctrl.countries = countries
        ctrl.countryOptions = []
        countries.forEach(function(country) {
          if (!country.supportedLanguages) return
          ctrl.languageOptionsByCountry[
            country.isoCode
          ] = country.supportedLanguages.map(function(language) {
            return {
              label: language.languageName,
              value: language.isoCode,
              data: language,
            }
          })
          ctrl.countryOptions.push({
            label: country.countryName,
            value: country.isoCode,
            data: country,
          })
        })
      })
      ctrl.draft = estimateService.create()
      ctrl.draft.lengthOfInterview.set(60) // matches LOI.type.estimateSeconds
      if (!ctrl.draft.pricing) {
        ctrl.isPlanField = true
        ctrl.selectedPlan = null
        plansService.list().then(function(plans) {
          ctrl.planOptions = _(plans)
            .filter(function(plan) {
              return plan.type === plan.Types.SAAS
            })
            .map(function(plan) {
              return {
                label: plan.name,
                value: plan.id,
                data: plan,
              }
            })
            .value()
        })
      }
      if (!ctrl.draft.currency) {
        ctrl.draft.currency = 'AUD'
      }
    }

    function setType(type) {
      ctrl.draft.type = type
      clearEstimate()
    }

    function setLoi(value) {
      ctrl.draft.lengthOfInterview.set(value)
      clearEstimate()
    }

    function selectPlan(value) {
      if (value === ctrl.selectedPlan) return

      var plan = _.find(ctrl.planOptions, { value: value }).data
      ctrl.draft.pricing = plan.pricing
      ctrl.selectedPlan = value
      clearEstimate()
    }

    function setCountry(value) {
      if (value === ctrl.draft.country) return

      var country = _.find(ctrl.countryOptions, { value: value }).data
      if (ctrl.draft.country !== country.isoCode) {
        var firstLanguage =
          ctrl.languageOptionsByCountry[country.isoCode][0].data
        ctrl.draft.country = country.isoCode
        ctrl.draft.language = firstLanguage.isoCode
      }

      ctrl.draft.presetId = null
      _.remove(ctrl.draft.targets)
      clearEstimate()
    }

    function setLanguage(value) {
      if (value === ctrl.draft.language) return

      var languageOptions = ctrl.languageOptionsByCountry[ctrl.draft.country]
      var language = _.find(languageOptions, { value: value }).data
      ctrl.draft.language = language.isoCode

      ctrl.draft.presetId = null
      _.remove(ctrl.draft.targets)
      clearEstimate()
    }

    function create() {
      estimateService.save(ctrl.draft).then(function() {
        ctrl.estimate = ctrl.draft.clone()
        ctrl.draft.refresh()
      })
    }

    function checkFeasibility() {
      requireSubscriber(function() {
        estimateService
          .checkFeasibility(ctrl.estimate)
          .then(function() {
            estimateService.save(ctrl.estimate)
          })
          .catch(function() {
            glToast.show(
              'There was an issue checking the feasibility of your estimate.'
            )
          })
      })
    }

    function email() {
      requireSubscriber(function() {
        estimateService.email(ctrl.estimate).then(function() {
          glToast.show('Estimate sent to: ' + userService.getUser().email)
        })
      })
    }

    function requireSubscriber(done) {
      if (!userService.isLoggedIn()) {
        return accountService.showAuthDialog('signup').then(done)
      }
      if (!ctrl.estimate.subscriberId) {
        ctrl.estimate.subscriberId = subscriberService.getSubscriber().id
        return estimateService.save(ctrl.estimate).then(done)
      }
      done()
    }

    function clampIncidenceRate() {
      ctrl.draft.incidenceRate = _.clamp(ctrl.draft.incidenceRate, 0, 100)
    }

    function clearEstimate() {
      ctrl.estimate = null
    }

    function createSurvey() {
      $state.go('survey', { surveyId: 'new', tab: 'editor' })
    }

    function goToKits() {
      $state.go('kits')
    }
  }
})()
