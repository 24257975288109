;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$state", "colors", "glAnalytics", "updateService", "subscriberService"];
  angular.module('app.core').component('statusBar', {
    controller: Controller,
    templateUrl: 'status-bar.html',
    bindings: {
      // ...
    },
  })

  /* @ngInject */
  function Controller(
    $rootScope,
    $state,
    colors,
    glAnalytics,
    updateService,
    subscriberService
  ) {
    var ctrl = this
    var Colors = colors.Colors

    ctrl.$onInit = onInit

    function onInit() {
      var frozen = subscriberService.isFrozen()
      if (updateService.isNewVersion()) {
        setNewVersionMessage()
      } else if (frozen) {
        setAccountFreezeMessage()
      }

      // watch for version changes
      $rootScope.$on(updateService.Events.NEW_VERSION_AVAILABLE, function() {
        setNewVersionMessage()
      })

      // watch for login/logout/updates
      $rootScope.$on(subscriberService.Events.CHANGE, function() {
        if (subscriberService.isFrozen()) {
          setAccountFreezeMessage()
        } else if (ctrl.message && ctrl.message.type === 'FROZEN') {
          ctrl.message = null
        }
      })
    }

    function setNewVersionMessage() {
      ctrl.message = {
        type: 'NEW_VERSION',
        text: 'A new version of Glow is available!',
        color: Colors.ORANGE_BLAZE,
        cta: 'Refresh',
        action: function() {
          window.location.reload()
          glAnalytics.track('status-bar', 'new-version-actioned')
        },
      }
      glAnalytics.track('status-bar', 'new-version-shown')
    }

    function setAccountFreezeMessage() {
      var subscriber = subscriberService.getSubscriber()
      var reason = subscriber.freeze.reason
      var messages = {
        CARD_FAILED:
          'Your account is frozen as your payment method has failed.',
        CREDIT_EXHAUSTED:
          'Your account is frozen as you have no payment method or credit remaining.',
      }
      var text = messages[reason]
      if (!text) throw new Error('setAccountFreezeMessage has invalid reason')
      ctrl.message = {
        type: 'FROZEN',
        text: text,
        color: Colors.RED_CANDY,
        cta: 'Update payment method',
        action: function() {
          $state.go('account', { tab: 'billing' }) // TODO: wrong state!?
          glAnalytics.track('status-bar', 'freeze-actioned')
        },
      }
      glAnalytics.track('status-bar', 'freeze-shown')
    }
  }
})()
