;(function() {
  'use strict'

  Factory.$inject = ["glUtils", "ThemeContext", "ResponseFilter", "DashboardView"];
  angular.module('app.core').factory('Dashboard', Factory)

  /* @ngInject */
  function Factory(glUtils, ThemeContext, ResponseFilter, DashboardView) {
    function Dashboard(survey) {
      this.survey = survey
      this.applyDefaults()
    }

    Dashboard.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: glUtils.uuid(),
        isNew: true,
        surveyId: this.survey.id,
        name: null,
        views: [],
        filters: [],
        prefilters: [],
        theme: new ThemeContext()
          .asSurveyDefault()
          .deserialize(this.survey.themeContext),
        completesOnly: true,
        createdAt: null,
        updatedAt: null,
      })
    }

    Dashboard.prototype.deserialize = function(data) {
      var self = this

      this.id = data.id
      this.isNew = false
      this.surveyId = data.surveyId
      this.name = data.name

      var subdata = _.isPlainObject(data.data) ? data.data : {}

      this.views = (subdata.views || []).map(function(data) {
        return new DashboardView().deserialize(data)
      })
      this.filters = (subdata.filters || []).map(function(data) {
        return new ResponseFilter(self.survey).deserialize(data)
      })
      this.prefilters = subdata.prefilters
      this.theme = new ThemeContext()
        .asSurveyDefault()
        .deserialize(subdata.theme || self.survey.themeContext)
      this.completesOnly = subdata.completesOnly

      this.createdAt = data.createdAt ? moment(data.createdAt) : null
      this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null
      this.applyDefaults()
      return this
    }

    Dashboard.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.surveyId = this.surveyId
      data.name = this.name

      var subdata = {}
      subdata.views = this.views.map(function(view) {
        return view.serialize()
      })
      subdata.filters = this.filters.map(function(filter) {
        return filter.serialize()
      })
      subdata.prefilters = this.prefilters.slice()
      subdata.theme = this.theme.serialize()
      subdata.completesOnly = this.completesOnly
      data.data = subdata

      data.createdAt = this.createdAt ? this.createdAt.toISOString() : null
      data.updatedAt = this.updatedAt ? this.updatedAt.toISOString() : null
      return data
    }

    Dashboard.prototype.clone = function() {
      return new Dashboard(this.survey).deserialize(this.serialize())
    }

    Dashboard.prototype.copy = function(datapack) {
      var isNew = this.isNew
      this.deserialize(datapack.serialize())
      this.isNew = isNew
    }

    Dashboard.prototype.refresh = function() {
      this.id = glUtils.uuid()
      this.isNew = true
      return this
    }

    Dashboard.prototype.commit = function() {
      this.serialized = JSON.stringify(this.serialize())
      return this
    }

    Dashboard.prototype.revert = function() {
      this.deserialize(JSON.parse(this.serialized))
    }

    Dashboard.prototype.isUnsaved = function() {
      // if (this.isNew) return true
      return this.serialized !== JSON.stringify(this.serialize())
    }

    return Dashboard
  }
})()
