;(function() {
  'use strict'

  Controller.$inject = ["$window", "$state", "$rootScope", "configService", "glTips", "glToast", "glUtils", "imagesService", "subscriberService", "userService", "app", "accountService"];
  angular.module('app.account').controller('Account', Controller)

  /* @ngInject */
  function Controller(
    $window,
    $state,
    $rootScope,
    configService,
    glTips,
    glToast,
    glUtils,
    imagesService,
    subscriberService,
    userService,
    app,
    accountService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.editLogo = editLogo
    ctrl.editFeature = editFeature
    ctrl.setTab = setTab
    ctrl.selectPlan = selectPlan

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.user = userService.getUser()
      ctrl.isAdmin = subscriberService.isAdmin(ctrl.user.id)
      ctrl.isSSR = userService.isSSR()
      ctrl.tabs = [
        { label: 'Details', value: 'details', title: 'Account Details' },
        { label: 'Users', value: 'users', title: 'Users' },
        { label: 'Plan', value: 'plan', title: 'Plan' },
        {
          label: 'Billing',
          value: 'billing',
          title: 'Billing',
          hidden: !ctrl.isAdmin && !ctrl.isSSR,
        },
        { label: 'Theme', value: 'theme', title: 'Account Theme' },
      ]
      if (ctrl.isSSR || ctrl.isAdmin) {
        ctrl.tabs.push({ label: 'Admin', value: 'admin', title: 'Admin' })
      }
      setTab($state.params.tab || 'details')
      ctrl.state = 'ready'
      glTips.ready()
    }

    function setTab(value) {
      ctrl.tab = value
      glUtils.changeParams({ tab: value })
      var title = _.find(ctrl.tabs, { value: value }).title
      app.setTitle(title)
    }

    function editLogo() {
      imagesService.prompt({
        title: 'Account Logo',
        imageUrl: ctrl.subscriber.portalTheme.logoImageUrl,
        save: function(imageUrl) {
          var oldImageUrl = ctrl.subscriber.portalTheme.logoImageUrl
          ctrl.subscriber.portalTheme.logoImageUrl = imageUrl
          return subscriberService.saveSubscriber(ctrl.subscriber).then(
            function() {
              glToast.show(imageUrl ? 'Logo updated' : 'Logo removed')
              $rootScope.$broadcast('account:logo-updated')
            },
            function() {
              ctrl.subscriber.portalTheme.logoImageUrl = oldImageUrl
              glToast.show('Upload error, please try again.')
            }
          )
        },
      })
    }

    function editFeature() {
      imagesService.prompt({
        title: 'Account Banner Image',
        imageUrl: ctrl.subscriber.portalTheme.featureImageUrl,
        save: function(imageUrl) {
          var oldImageUrl = ctrl.subscriber.portalTheme.featureImageUrl
          ctrl.subscriber.portalTheme.featureImageUrl = imageUrl
          return subscriberService.saveSubscriber(ctrl.subscriber).then(
            function() {
              glToast.show(imageUrl ? 'Feature updated' : 'Feature removed')
            },
            function() {
              ctrl.subscriber.portalTheme.featureImageUrl = oldImageUrl
              glToast.show('Upload error, please try again.')
            }
          )
        },
      })
    }

    function selectPlan(plan, currency) {
      if (!plan.isFree()) {
        accountService.showPurchaseDialog({
          type: 'plan',
          plan: plan,
          currency: currency,
          context: 'account-plans',
        })
      } else {
        accountService.showDowngradeDialog(plan).then(function() {
          var downgradeUrl = configService.getDowngradeUrl()
          if (downgradeUrl) {
            $window.open(downgradeUrl, '_blank')
          }
        })
      }
    }
  }
})()
