;(function() {
  'use strict'

  Service.$inject = ["Money", "plansService", "subscriberService"];
  angular.module('app.core').service('channelCostService', Service)

  /* @ngInject */
  function Service(Money, plansService, subscriberService) {
    var self = this

    self.calculate = calculate

    window.channelCostService = this

    function calculate(channel) {
      if (channel.type === channel.Type.PANEL) {
        return calculatePanel(channel)
      }
      return calculateBasic(channel)
    }

    function calculatePanel(channel) {
      var subscriber = subscriberService.getSubscriber()
      var currency = subscriber.billing.currency
      var balance = subscriber.billing.balance
      var cost = {}
      var base = subscriber.plan.pricing.base.byCurrency(currency)
      var response = _(subscriber.plan.pricing.responses)
        .find({
          type: channel.panel.lengthOfInterview.type.type,
        })
        .price.byCurrency(currency)

      // cost per response is BASE + PANEL
      cost.response = base.clone()
      cost.response.amount += response.amount

      cost.balance = balance.clone()
      cost.exit = subscriber.plan.pricing.exits.byCurrency(currency)

      // calculate total if we have the required data
      if (channel.panel.incidenceRate && channel.panel.requiredCompletes) {
        var incidencePercent = channel.panel.incidenceRate / 100
        var totalExits =
          channel.panel.requiredCompletes / incidencePercent -
          channel.panel.requiredCompletes
        var totalResponses = channel.panel.requiredCompletes
        var totalResponseCost = totalResponses * cost.response.amount
        var totalExitCost = totalExits * cost.exit.amount

        cost.totalResponses = totalResponses
        cost.total = new Money({ currency: currency })
        cost.total.amount = Math.round(totalResponseCost + totalExitCost)
        cost.complete = true
      } else {
        cost.complete = false
      }

      cost.hasEnoughBalance = cost.total && balance.amount >= cost.total.amount

      // console.log('calculatePanel', cost)
      return cost
    }

    function calculateBasic(channel) {
      var subscriber = subscriberService.getSubscriber()
      var currency = subscriber.billing.currency
      var base = subscriber.plan.pricing.base.byCurrency(currency)
      var balance = subscriber.billing.balance
      var totalResponses = channel.responseLimit
      var cost = {}

      cost.balance = balance.clone()
      // cost per response is just BASE
      cost.response = new Money({ currency: currency })
      cost.response.amount = base.amount
      cost.exit = new Money({ currency: currency })
      if (totalResponses) {
        cost.totalResponses = totalResponses
        cost.total = new Money({ currency: currency })
        cost.total.amount = cost.response.amount * totalResponses
        cost.complete = true
      } else {
        cost.complete = false
      }

      cost.hasEnoughBalance = cost.total && balance.amount >= cost.total.amount

      // console.log('calculateBasic', cost)
      return cost
    }
  }
})()
